import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import { AppService } from '../services/app.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  NgForm,
} from '@angular/forms';
import { StaticData } from '../shared/staticData';
import { ExportExcelService } from '../services/export-excel.service';
import { Router } from '@angular/router';
import { PreviousRouteService } from '../services/prevoiusRoute.service';
import { CommonService } from '../services/common.service';
import { DataShareService } from '../services/data-share.service';
import { HttpService } from '../services/http.service';
import { HttpParams } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { from } from 'rxjs';
import * as moment from 'moment';
import { AlertService } from '../alert-dialog/alert.service';
declare var $: any;
import * as dayjs from 'dayjs';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { RangeSelectionStrategy } from '../services/date-range-picker-selection-strategy';

@Component({
  selector: 'app-claim-management',
  templateUrl: './claim-management.component.html',
  styleUrls: ['./claim-management.component.scss'],
})
export class ClaimManagementComponent implements OnInit, AfterViewInit {
  @ViewChild('select')
  select!: MatSelect;
  allSelected = false;
  pageType = 'claim_management';
  userRole: any;
  columnFilterControl = new FormControl([]);
  @ViewChild(MatSort) sort: MatSort | any;

  displayedColumns: any = [];
  dataSource: any;
  columnsOption: any = [];
  columnsToDisplay: any = [];
  documentType: any = [];

  advancedSearchForm: any;
  socDetail: any;
  serviceType: any;
  statusSelect = new FormControl();

  excelDummyData: any[] = StaticData.claimManagementTableData;
  mainFilter = false;
  dropdownSettings = {};
  columnMappingsData: any = {};
  userId: any;
  masterData: any;
  advanceFilterDocumentTypeList: any;
  advanceFilterServiceTypeList: any;
  advanceFilterAccountingIndicatorList: any;
  advanceFilterStatusList: any;

  // statusDropdownSettings:any;
  accountIndDropdownSettings: any;
  spDropdownSettings: any;
  mainStatusDropdownSettings: any;
  technicianDropdownSettings: any;
  servicetypeDropdownSettings: any;
  documentTypeDropdownSettings: any;
  dmDropdownSettings: any;
  selectedSp: any = [];
  selectedTechnicians: any = [];
  editableSocNames: any = [];
  editableSocs: any = [];
  customerId: any;
  selectedStatus: any = [];
  filterMinDate: any = ''; //new Date(new Date().setDate(new Date().getDate()-90));
  // *****For chiplist**********
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  separatorKeysCodes = [ENTER, COMMA];
  socNumbers: any = [];
  soNumbers: any = [];
  customerNumbers: any = [];
  // ******************
  isTableDataSet: boolean = false;
  comingFromMainFilter: boolean = false;
  receivedSocData: any;
  @ViewChild('creationdateRangeInput') creationdateRangeInput: ElementRef | any;
  @ViewChild('appointmentdateRangeInput') appointmentdateRangeInput:
    | ElementRef
    | any;
  @ViewChild('calldateRangeInput') calldateRangeInput: ElementRef | any;

  /** ***for ngxDtepicker** */
  @ViewChild('creationDatePicker') creationDatePicker: ElementRef | any;
  @ViewChild('appointmentDatePicker') appointmentDatePicker: ElementRef | any;
  @ViewChild('callDatePicker') callDatePicker: ElementRef | any;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  maxDate: dayjs.Dayjs = dayjs();
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    public ete: ExportExcelService,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private commonService: CommonService,
    private alertService: AlertService,
    private httpService: HttpService,
    public matDialog: MatDialog,
    private loaderService: NgxUiLoaderService,
    private dataShareService: DataShareService
  ) {
    this.appService.setTitle('Claim Management');
    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');
    this.customerId = this.dataShareService.getLocalStorage('customerNumber');
    this.dataSource = new MatTableDataSource();
    this.setFormValues();
    this.getRoleHierarchyMapping();
    this.filterMinDate = '';
    //new Date(new Date().setDate(new Date().getDate()-90));
  }
  setFormValues() {
    this.advancedSearchForm = this.formBuilder.group({
      accountingIndicator: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .accountingIndicator
          : '',
      ],
      appointmentDateFrom: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .appointmentDateFrom
          : '',
      ],
      appointmentDateTo: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .appointmentDateTo
          : '',
      ],
      callDateFrom: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().callDateFrom
          : '',
      ],
      callDateTo: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().callDateTo
          : '',
      ],
      creationDateFrom: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .creationDateFrom
          : '',
      ],
      creationDateTo: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().creationDateTo
          : '',
      ],
      customerNumber: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().customerNumber
          : '',
      ],
      documentStatus: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().documentStatus
          : '',
        [Validators.required],
      ],
      documentType: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().documentType
          : '',
      ],
      externalReference: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .externalReference
          : '',
      ],
      serviceType: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().serviceType
          : '',
      ],
      technicians: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().technicians
          : '',
      ],
      documentNumber: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().documentNumber
          : '',
      ],
      serviceProvider: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().serviceProvider
          : '',
      ],
      districtsManagers: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter()
              .districtsManagers
          : '',
      ],
      servicePartners: [
        this.dataShareService.getFilterSocRequestParameter()
          ? this.dataShareService.getFilterSocRequestParameter().servicePartners
          : '',
        [Validators.required],
      ],
    });
    if (this.dataShareService.getLocalStorage('socFilterParameter')) {
      let socFilterParameter: any =
        this.dataShareService.getLocalStorage('socFilterParameter');
      socFilterParameter = JSON.parse(socFilterParameter);
      this.socNumbers = socFilterParameter.documentNumber
        ? socFilterParameter.documentNumber
        : [];
      this.customerNumbers = socFilterParameter.customerNumber
        ? socFilterParameter.customerNumber
        : [];
      this.soNumbers = socFilterParameter.externalReference
        ? socFilterParameter.externalReference
        : [];
    }
    this.selectedDMs = this.dataShareService.getFilterSocRequestParameter()
      ? this.dataShareService.getFilterSocRequestParameter().districtsManagers
      : [];
    this.selectedSp = this.dataShareService.getFilterSocRequestParameter()
      ? this.dataShareService.getFilterSocRequestParameter().servicePartners
      : [];
    this.selectedTechnicians =
      this.dataShareService.getFilterSocRequestParameter()
        ? this.dataShareService.getFilterSocRequestParameter().technicians
        : [];
    this.selectedAdvancedFilterDMs =
      this.dataShareService.getFilterSocRequestParameter()
        ? this.dataShareService.getFilterSocRequestParameter().districtsManagers
        : [];
    this.selectedAdvancedFilterSp =
      this.dataShareService.getFilterSocRequestParameter()
        ? this.dataShareService.getFilterSocRequestParameter().servicePartners
        : [];
    this.selectedAdvancedFilterTechnicians =
      this.dataShareService.getFilterSocRequestParameter()
        ? this.dataShareService.getFilterSocRequestParameter().technicians
        : [];
    this.selectedStatus = this.dataShareService.getFilterSocRequestParameter()
      ? this.dataShareService.getFilterSocRequestParameter().documentStatus
      : [];
    console.log(this.advancedSearchForm.value);
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.servicetypeDropdownSettings = {
      singleSelection: false,
      idField: 'serviceType',
      textField: 'serviceTypeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.documentTypeDropdownSettings = {
      singleSelection: false,
      idField: 'docType',
      textField: 'docTypeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.technicianDropdownSettings = {
      singleSelection: false,
      idField: 'technicianId',
      textField: 'technicianName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:
        'Please Select Atleast One Service Partner',
    };
    this.mainStatusDropdownSettings = {
      singleSelection: this.mainFilter ? true : false,
      idField: 'estat',
      textField: 'socStatusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    // this.statusDropdownSettings = {
    //   singleSelection: false,
    //   idField:  this.userRole=="SP"? 'estat':'socStatus',
    //   textField: 'socStatusName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };
    this.accountIndDropdownSettings = {
      singleSelection: false,
      idField: 'accountingIndicator',
      textField: 'accountingIndicatorDesc',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dmDropdownSettings = {
      singleSelection: true,
      idField: 'dmId',
      textField: 'dmName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.spDropdownSettings = {
      singleSelection: false,
      idField: 'spId',
      textField: 'spName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:
        'Please Select Atleast One District Manager',
    };

    if (
      this.userRole != 'SP' &&
      this.dataShareService.getClaimMainFilterFlag()
    ) {
      this.mainFilter = true;
      this.mainStatusDropdownSettings.singleSelection = true;
    } else {
      this.mainFilter = false;
      this.mainStatusDropdownSettings.singleSelection = false;
    }
    this.getUserColumnMaping();
    this.getUserMasterData(this.pageType);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  ngAfterViewInit() {
    // console.log(this.creationdateRangeInput)
    this.setDefaultMaxDate();
  }
  setDefaultMaxDate() {
    setTimeout(() => {
      this.creationdateRangeInput.max = new Date();
      this.appointmentdateRangeInput.max = new Date();
      this.calldateRangeInput.max = new Date();
      this.creationdateRangeInput.min = '';
      this.appointmentdateRangeInput.min = '';
      this.calldateRangeInput.min = '';
    });
  }
  setPaginator() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });
  }
  searchAgain() {
    this.mainStatusDropdownSettings.singleSelection = true;
    this.mainFilter = true;
    this.setFormValues();
    this.setDefaultMaxDate();
  }
  cancelSearchAgain() {
    this.mainFilter = false;
    this.mainStatusDropdownSettings.singleSelection = false;
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    }, 3000);
    this.setPaginator();
    this.setDefaultMaxDate();
  }
  // *****Column filter start******
  _tempColumnsToDisplay: any = [];
  addColumn() {
    this.columnFilterControl.value.forEach((element: any) => {
      if (!this._tempColumnsToDisplay.includes(element)) {
        if (element == 'socId') {
          this._tempColumnsToDisplay.unshift(element);
        } else {
          this._tempColumnsToDisplay.push(element);
        }
        if (!this._tempColumnsToDisplay.includes('socId')) {
          this._tempColumnsToDisplay.unshift('socId');
        }
      }
    });
  }

  removeColumn() {
    if (this._tempColumnsToDisplay.length) {
      let columnsToDisplay = JSON.parse(
        JSON.stringify(this._tempColumnsToDisplay)
      );
      this._tempColumnsToDisplay.forEach((element: any, index: any) => {
        if (!this.columnFilterControl.value.includes(element)) {
          let i = columnsToDisplay.indexOf(element);
          if (i !== -1) {
            columnsToDisplay.splice(i, 1);
          }
        }
        if (this.columnFilterControl.value.length == 0) {
          this._tempColumnsToDisplay = [];
        }
      });
      this._tempColumnsToDisplay = columnsToDisplay;
      if (!this._tempColumnsToDisplay.includes('socId')) {
        this._tempColumnsToDisplay.unshift('socId');
      }
    }
  }

  initialSelect() {
    this.select.options.forEach((item: MatOption) => {
      if (this._tempColumnsToDisplay.includes(item.value)) {
        item.select();
      }
    });
    if (this._tempColumnsToDisplay.length == this.displayedColumns.length) {
      this.allSelected = true;
    }
    this.onAdvancedFilterModelClosed();
  }

  toggleAllSelection() {
    this._tempColumnsToDisplay = [];
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.addColumn();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this._tempColumnsToDisplay = ['socId'];
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.addColumn();
    this.removeColumn();
  }
  onColumnRemoved(col: string) {
    const cols = this.columnFilterControl.value as string[];
    this.removeFirst(cols, col);
    this.columnFilterControl.setValue(cols); // To trigger change detection
    this.allSelected = false;
    this.removeColumn();
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  // *****Column filter End******
  exportToSheet() {
    let claimManagementTableCoulumn = [
      { label: 'Service Confirmation ID', value: 'socId' },
      { label: 'Vendor', value: 'vendorName' },
      { label: 'Technician', value: 'technician' },
      { label: 'Document Type', value: 'docType' },
      { label: 'Status', value: 'status' },
      { label: 'Creation Date', value: 'creationDate' },
      { label: 'Appointment Date', value: 'appointmentDate' },
      { label: 'Call Date', value: 'callDate' },
      { label: 'Accounting Indicator', value: 'accountingIndicator' },
      { label: 'Service Type', value: 'serviceType' },
      { label: 'Service Order ID', value: 'externalReference' },
      // { label: 'Customer Number', value: 'customerNumber' },
    ];
    let paged = this.commonService.prepareTableDataForCsv(
      this.dataSource,
      this.columnsToDisplay,
      this.columnsOption,
      this.paginator
    );
    let fields = this.commonService.headerNameChange(
      paged,
      claimManagementTableCoulumn
    );
    console.log(fields);
    this.commonService.downloadCSV(paged, StaticData.socClaimSheet, fields);
  }
  selectSoc(soc: any) {
    // this.socDetail = soc;
    // this.router.navigate(['home/soc-details'])
  }
  resetAdvanceFilterForm() {
    this.advancedSearchForm.reset();
    // this.creationDatePicker.nativeElement.value="";
    // this.appointmentDatePicker.nativeElement.value="";
    // this.callDatePicker.nativeElement.value="";
    this.socNumbers = [];
    this.customerNumbers = [];
    this.soNumbers = [];
    this.setDefaultMaxDate();
  }
  onAdvancedFilterSubmit() {
    // if(this.userRole=="SP"){
    //   this.retriveSocListForSp();
    // }else{
    //   this.getSOCs();
    // }
    this.getSOCs();
  }
  resetMainFilterForm() {
    this.advancedSearchForm.reset();
    this.socNumbers = [];
    this.customerNumbers = [];
    this.selectedSp = [];
    this.soNumbers = [];
    this.setDefaultMaxDate();
  }
  mainFilterSearch() {
    console.log('Retrieve data:', this.receivedSocData);
    // this.dataShareService.currentFormData.subscribe((jsonString) => {
    //   this.receivedSocData = JSON.parse(jsonString);
    //   console.log('Retrieve data:', this.receivedSocData);
    // });
    this.advancedSearchForm.get('documentType').setValue('');
    this.customerNumbers = [];
    this.comingFromMainFilter = true;

    if (this.userRole == 'SP') {
      this.retriveSocListForSp();
    } else {
      this.retriveSocList();
    }
    setTimeout(() => {
      this.setDefaultMaxDate();
    });
  }
  getUserColumnMaping() {
    this.loaderService.start();
    this.httpService
      .get('columnPreferenceApi/v1/getColumnMappings?pageType=' + this.pageType)
      .subscribe(
        (data: any) => {
          delete data.columnMappings.docNumber;
          this.columnMappingsData = data.columnMappings;

          for (var element in data.columnMappings) {
            if (data.columnMappings.hasOwnProperty(element)) {
              let obj: any = {};
              obj['id'] = element;
              obj['value'] = data.columnMappings[element];
              if (element == 'socId') {
                this.displayedColumns.splice(0, 0, obj);
              } else {
                this.displayedColumns.push(obj);
              }
            }
          }
          this.columnsOption = this.displayedColumns;
          this.getUserColumnPref();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  getUserColumnPref() {
    this.httpService
      .get(
        'columnPreferenceApi/v1/getUserColumnPreferences?userId=' + this.userId
      )
      .subscribe(
        (data: any) => {
          let hasPageTypeData: boolean = false;
          if (data.userPreferenceActive) {
            data.columnPreferencesList.forEach((list: any) => {
              if (list.pageType == this.pageType) {
                hasPageTypeData = true;
                if (list.preferredColumnList.length == 0) {
                  this.displayedColumns.forEach((element: any) => {
                    this.columnsToDisplay.push(element.id);
                  });
                } else {
                  list.preferredColumnList.forEach((element: any) => {
                    if (element == 'socId') {
                      this.columnsToDisplay.unshift(element);
                    } else {
                      this.columnsToDisplay.push(element);
                    }
                  });
                }
              }
            });
            if (!hasPageTypeData) {
              this.displayedColumns.forEach((element: any) => {
                this.columnsToDisplay.push(element.id);
              });
            }
          } else {
            this.displayedColumns.forEach((element: any) => {
              this.columnsToDisplay.push(element.id);
            });
          }
          this._tempColumnsToDisplay = this.columnsToDisplay;
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  setUserColumnPref() {
    let paramdata = '';
    this.columnsToDisplay = this._tempColumnsToDisplay;
    this.columnsToDisplay.forEach((element: any) => {
      paramdata = paramdata + 'columnList=' + element + '&';
    });
    paramdata = paramdata + 'pageType=claim_management&';
    paramdata = paramdata + 'userId=' + this.userId;
    this.httpService
      .post('columnPreferenceApi/v1/setUserColumnPreferences?' + paramdata)
      .subscribe(
        (data: any) => {
          this.loaderService.stop();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  getUserMasterData(pageType: any) {
    this.loaderService.start();
    this.httpService
      .get(
        'userApi/v1/getUserMasterData?code=' +
          this.dataShareService.getLocalStorage('role') +
          '&page_type=' +
          pageType
      )
      .subscribe(
        (data: any) => {
          this.masterData = data.response;
          //** Removing document type for consolidated consolidated claim*/
          for (let i = 0; i < this.masterData.documentTypeDetail.length; i++) {
            if (this.masterData.documentTypeDetail[i].docType == 'ZUK7') {
              this.masterData.documentTypeDetail.splice(i, 1);
            }
          }
          this.getUserSocMapping();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  onAdvancedFilterModelClosed() {
    let self = this;
    $('#exampleModalCenter').on('click', function (e: any) {});
  }
  dmList: any = [];
  selectedDMs: any = [];
  serviceProvidersList: any = [];
  techniciansList: any = [];
  selectedAdvancedFilterDMs: any = [];
  selectedAdvancedFilterSp: any = [];
  selectedAdvancedFilterTechnicians: any = [];
  getRoleHierarchyMapping() {
    this.httpService
      .get('roleHierarchyMapping/v1/getRoleHierarchyMapping')
      .subscribe(
        (data: any) => {
          this.dmList = data.districtManagerDetails;
          if (this.userRole == 'SP') {
            this.getTechnicianListForSP();
          }
          //  this.selectedDMs = this.dmList;
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }

  onDMDropDownClose() {
    this.serviceProvidersList = [];
    this.selectedDMs == null
      ? (this.selectedDMs = this.dmList)
      : (this.selectedDMs = this.selectedDMs);
    this.dmList.forEach((dm: any) => {
      this.selectedDMs.forEach((selectedDm: any) => {
        if (dm.dmId == selectedDm.dmId) {
          this.serviceProvidersList = this.serviceProvidersList.concat(
            dm.serviceProviderDetailsList
          );
        }
      });
    });
  }
  onMainDMDropDownClose() {
    this.selectedSp = [];
    this.selectedTechnicians = [];
    this.serviceProvidersList = [];
    this.selectedDMs == null
      ? (this.selectedDMs = this.dmList)
      : (this.selectedDMs = this.selectedDMs);
    this.dmList.forEach((dm: any) => {
      this.selectedDMs.forEach((selectedDm: any) => {
        if (dm.dmId == selectedDm.dmId) {
          this.serviceProvidersList = this.serviceProvidersList.concat(
            dm.serviceProviderDetailsList
          );
        }
      });
    });
  }
  onDmDeSelect() {
    this.selectedSp = [];
    this.selectedTechnicians = [];
    this.serviceProvidersList = [];
  }
  onSPDropDownClose() {
    this.techniciansList = [];
    this.selectedSp == null
      ? (this.selectedSp = this.serviceProvidersList)
      : (this.selectedSp = this.selectedSp);
    this.serviceProvidersList.forEach((sp: any) => {
      this.selectedSp.forEach((selectedSp: any) => {
        if (selectedSp.spId == sp.spId) {
          this.techniciansList = this.techniciansList.concat(
            sp.technicianDetailsList
          );
        }
      });
    });
  }
  onSpDeSelect() {
    this.selectedTechnicians = [];
  }
  getTechnicianListForSP() {
    this.techniciansList = [];
    for (let i = 0; i < this.dmList.length; i++) {
      for (
        let j = 0;
        j < this.dmList[i].serviceProviderDetailsList.length;
        j++
      ) {
        if (
          this.dmList[i].serviceProviderDetailsList[j].spId == this.customerId
        ) {
          this.techniciansList =
            this.dmList[i].serviceProviderDetailsList[j].technicianDetailsList;
          break;
        }
      }
    }
  }
  getUserSocMapping() {
    const code = this.dataShareService.getLocalStorage('role');
    this.httpService
      .get(
        'claimApi/v1/getUserSOCMapping?action=soc_detail&page_type=claim_management&code=' +
          code
      )
      .subscribe(
        (data: any) => {
          let userSocMapings = data.response;
          this.editableSocNames = [];
          this.editableSocs = [];
          this.masterData.socStatusDetail.forEach((element: any) => {
            /** prepare default socList*/
            if (this.userRole == 'SP') {
              if (userSocMapings.editableSOC.includes(element.socStatus)) {
                this.editableSocNames.push(element.socStatusName);
                if (!this.dataShareService.getFilterSocRequestParameter()) {
                  this.selectedStatus.push(element);
                  this.advancedSearchForm
                    .get('documentStatus')
                    ?.setValue(this.selectedStatus);
                } else {
                  this.selectedStatus = JSON.parse(
                    JSON.stringify(
                      this.dataShareService.getFilterSocRequestParameter()
                        .documentStatus
                    )
                  );
                  this.advancedSearchForm
                    .get('documentStatus')
                    ?.setValue(
                      this.dataShareService.getFilterSocRequestParameter()
                        .documentStatus
                    );
                }
              }
            }
            /**End */

            /** prepare socList*/
            if (this.userRole == 'SP') {
              if (
                element.socStatus != 'CANC' &&
                element.socStatus != 'CLO' &&
                element.socStatus != 'INV' &&
                element.socStatus != 'CERR'
              ) {
                this.editableSocs.push(element);
              }
            }
            if (this.userRole == 'DM' || this.userRole == 'AM') {
              this.editableSocs.push(element);
            }
            /**End */
          });

          if (this.userRole == 'SP') {
            //check if user is sp then directly call filterConsolidateClaim API.
            if (this.commonService.getPreviousUrl().includes('login')) {
              this.retriveSocListForSp();
            } else {
              this.getSOCs();
            }
          } else if (!this.dataShareService.getClaimMainFilterFlag()) {
            //check if user is not sp and already done main filterring then directly call filterConsolidateClaim API.
            this.getSOCs();
          } else {
            //check if user is not sp and  user not filtered with  mainFilter then show mainFilter
            this.loaderService.stop();
          }
        },
        (err) => {
          this.loaderService.stop();
        }
      );
  }
  addSocNumbers(event: MatChipInputEvent): void {
    this.addChip(event, this.socNumbers);
  }
  removeSocNumbers(keyword: any) {
    this.removeChip(keyword, this.socNumbers);
  }
  addSoNumbers(event: MatChipInputEvent): void {
    this.addChip(event, this.soNumbers);
  }
  removeSoNumbers(keyword: any) {
    this.removeChip(keyword, this.soNumbers);
  }
  addCustomerNumbers(event: MatChipInputEvent): void {
    this.addChip(event, this.customerNumbers);
  }
  removeCustomerNumbers(keyword: any) {
    this.removeChip(keyword, this.customerNumbers);
  }
  addChip(event: MatChipInputEvent, data: any) {
    let input = event.input;
    let value = event.value;
    // Add our keyword
    if ((value || '').trim()) {
      data.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeChip(keyword: any, data: any): void {
    let index = data.indexOf(keyword);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }
  pasteChips(event: ClipboardEvent | any, fieldName: string): void {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/;|,|\n| /) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach((value: any) => {
        if (value.trim()) {
          if (fieldName == 'socId') {
            this.socNumbers.push(value.trim()); //Push if valid
          } else if (fieldName == 'soId') {
            this.soNumbers.push(value.trim());
          } else if (fieldName == 'customerNo') {
            this.customerNumbers.push(value.trim());
          }
        }
      });
  }
  retriveSocList() {
    this.loaderService.start();
    let formData = JSON.parse(
      JSON.stringify(this.advancedSearchForm.getRawValue())
    );
    this.dataShareService.setData(formData);
    console.log('formData : '+formData);
    let data = {
      accountingIndicator: this.retriveValueFromMultiselect(
        formData.accountingIndicator,
        'accountingIndicator'
      ),
      appointmentDateFrom: formData.appointmentDateFrom
        ? moment(formData.appointmentDateFrom.toString()).format('YYYYMMDD')
        : '',
      appointmentDateTo: formData.appointmentDateTo
        ? moment(formData.appointmentDateTo.toString()).format('YYYYMMDD')
        : '',
      callDateFrom: formData.callDateFrom
        ? moment(formData.callDateFrom.toString()).format('YYYYMMDD')
        : '',
      callDateTo: formData.callDateTo
        ? moment(formData.callDateTo.toString()).format('YYYYMMDD')
        : '',
      creationDateFrom: formData.creationDateFrom
        ? moment(formData.creationDateFrom.toString()).format('YYYYMMDD')
        : '',
      creationDateTo: formData.creationDateTo
        ? moment(formData.creationDateTo.toString()).format('YYYYMMDD')
        : '',
      customer: this.customerNumbers,
      documentStatus:
        this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
          .length > 0
          ? this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
          : [],
      language: 'en',
      searchType: '02',
      serviceType: this.retriveValueFromMultiselect(
        formData.serviceType,
        'serviceType'
      ),
      soId: this.soNumbers,
      socid: this.socNumbers,
      spCustomerCode: this.retriveValueFromMultiselect(
        formData.servicePartners,
        'spId'
      ) /**Also known as customerId */,
      technician: this.retriveValueFromMultiselect(
        formData.technicians,
        'technicianId'
      ),
      userId: this.dataShareService.getLocalStorage('userId'),
      userProfile: this.userRole,
      toBeUpdated: false,
      socDbId: '',
      isConsolidated: false,
    };
    this.dataShareService.setLocalStorage(
      'retriveSocListRequestDto',
      JSON.stringify(data)
    );
    this.httpService.post('claimApi/v1/retrieveSocList', data).subscribe(
      (res) => {
        this.getSOCs();
        console.log(res);
        // if(data.status && data.status!='ERROR'){
        //   this.getSOCs();
        // }else{
        //   this.loaderService.stop();
        //   this.isTableDataSet=true;
        //   // this.alertService.openErrorDialogBox('Something went wrong. Please try again or contact admin!')
        // }
      },
      (err) => {
        this.loaderService.stop();
        console.log(err);
      }
    );
  }
  getSOCs() {
    this.loaderService.start();
    this.isTableDataSet = false;
    let formData = JSON.parse(
      JSON.stringify(this.advancedSearchForm.getRawValue())
    );
    console.log(formData);
    this.dataShareService.setFilterSocRequestParameter(
      this.advancedSearchForm.getRawValue()
    );
    let token = localStorage.getItem('userToken');
    let spVendorCode = [];
    if (this.userRole == 'SP') {
      spVendorCode = [this.dataShareService.getLocalStorage('customerNumber')];
    } else {
      spVendorCode =
        this.retriveValueFromMultiselect(formData.servicePartners, 'spId')
          .length > 0
          ? this.retriveValueFromMultiselect(formData.servicePartners, 'spId')
          : [];
    }

    let dto = {
      accountingIndicator:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.accountingIndicator,
              'accountingIndicator'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.accountingIndicator,
              'accountingIndicator'
            )
          : [],
      appointmentDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.appointmentDateFrom
          ? moment(formData.appointmentDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      appointmentDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.appointmentDateTo
          ? moment(formData.appointmentDateTo.toString()).format('YYYY-MM-DD')
          : '',
      callDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.callDateFrom
          ? moment(formData.callDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      callDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.callDateTo
          ? moment(formData.callDateTo.toString()).format('YYYY-MM-DD')
          : '',
      creationDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.creationDateFrom
          ? moment(formData.creationDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      creationDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.creationDateTo
          ? moment(formData.creationDateTo.toString()).format('YYYY-MM-DD')
          : '',
      customerNumber:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.customerNumbers,
      documentStatus:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.documentStatus,
              'socStatusName'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.documentStatus,
              'socStatusName'
            )
          : [],
      documentType:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(formData.documentType, 'docType')
              .length > 0
          ? this.retriveValueFromMultiselect(formData.documentType, 'docType')
          : [],
      externalReference: this.soNumbers,
      serviceType:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.serviceType,
              'serviceType'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.serviceType,
              'serviceType'
            )
          : [],
      technician:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.technicians,
              'technicianId'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.technicians,
              'technicianId'
            )
          : [],
      documentNumber: this.socNumbers,
      spVendorCode: spVendorCode,
      userId: this.userId,
      token: token,
      isConsolidated: false,
    };
    this.dataShareService.setLocalStorage(
      'socFilterParameter',
      JSON.stringify(dto)
    );
    this.httpService.post('claimApi/v1/filterSoc', dto).subscribe(
      (data) => {
        if (data.status != 'ERROR') {
          data.response.socInfoDetails.sort((a:any,b:any)=>a.socId-b.socId);
          this.dataShareService.setSocData(data.response.socInfoDetails);
          this.dataShareService.setLocalStorage(
            'lastFetchTime',
            data.response.lastDataFetchTime != null
              ? data.response.lastDataFetchTime
              : ''
          );
          this.dataSource = new MatTableDataSource(
            data.response.socInfoDetails
          );
          this.dataShareService.setSocListData(data.response.socInfoDetails);
          // if(this.userRole!='SP' ){
          if (this.comingFromMainFilter) {
            this.dataShareService.setClaimadvanceFilterStatusList(
              this.getFilterDropdownsData(
                data.response.statusFilter,
                'socStatusName',
                this.masterData.socStatusDetail
              )
            );
            this.dataShareService.setClaimAdvanceFilterDocumentTypeList(
              this.getFilterDropdownsData(
                data.response.docTypeFilter,
                'docTypeName',
                this.masterData.documentTypeDetail
              )
            );
            this.dataShareService.setClaimAdvanceFilterAccountingIndicatorList(
              this.getFilterDropdownsData(
                data.response.accountingIndicatorFilter,
                'accountingIndicatorDesc',
                this.masterData.accountingIndicatorDetail
              )
            );
            this.dataShareService.setClaimAdvanceFilterServiceTypeList(
              this.getFilterDropdownsData(
                data.response.serviceTypeFilter,
                'serviceTypeName',
                this.masterData.serviceDetail
              )
            );
          }
          this.advanceFilterStatusList =
            this.dataShareService.getClaimadvanceFilterStatusList();
          this.advanceFilterDocumentTypeList =
            this.dataShareService.getClaimAdvanceFilterDocumentTypeList();
          this.advanceFilterAccountingIndicatorList =
            this.dataShareService.getClaimAdvanceFilterAccountingIndicatorList();
          this.advanceFilterServiceTypeList =
            this.dataShareService.getClaimAdvanceFilterServiceTypeList();
          // }else {
          //   this.advanceFilterStatusList = this.masterData.socStatusDetail;
          //   this.advanceFilterDocumentTypeList = this.masterData.documentTypeDetail;
          //   this.advanceFilterAccountingIndicatorList =this.masterData.accountingIndicatorDetail;
          //   this.advanceFilterServiceTypeList = this.masterData.serviceDetail;
          // }
          this.comingFromMainFilter = false;
          this.dataShareService.setClaimMainFilterFlag(false);
          if (this.mainFilter) {
            this.mainFilter = false;
            this.setFormValues();
          }
          setTimeout(() => {
            this.dataSource.sort = this.sort;
          }, 3000);

          this.setPaginator();
          this.loaderService.stop();
          this.isTableDataSet = true;
        } else {
          this.loaderService.stop();
          this.isTableDataSet = false;
          this.alertService.openErrorDialogBox(
            'Something went wrong. Please try again or contact admin!'
          );
        }
      },
      (error) => {
        this.loaderService.stop();
        this.isTableDataSet = false;
      }
    );
  }
  getFilterDropdownsData(data: any, masterDatakeyName: any, masterdata: any) {
    let values: any = [];
    if (data && data.length > 0) {
      data.forEach((dataElement: any) => {
        masterdata.forEach((msaterDataElement: any) => {
          if (
            dataElement &&
            dataElement.trim().toLowerCase() ==
              msaterDataElement[masterDatakeyName].trim().toLowerCase()
          ) {
            values.push(msaterDataElement);
          }
        });
      });
    }
    return values;
  }
  retriveValueFromMultiselect(data: any, keyName: any) {
    let values: any = [];
    if (data && data.length > 0) {
      data.forEach((element: any) => {
        values.push(element[keyName]);
      });
    }
    return values;
  }
  filterSOcs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  retriveSocListForSp() {
    this.loaderService.start();
    let formData = JSON.parse(
      JSON.stringify(this.advancedSearchForm.getRawValue())
    );
    let data: any = {
      accountingIndicator:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.accountingIndicator,
              'accountingIndicator'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.accountingIndicator,
              'accountingIndicator'
            )
          : [],
      appointmentDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.appointmentDateFrom
          ? moment(formData.appointmentDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      appointmentDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.appointmentDateTo
          ? moment(formData.appointmentDateTo.toString()).format('YYYY-MM-DD')
          : '',
      callDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.callDateFrom
          ? moment(formData.callDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      callDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.callDateTo
          ? moment(formData.callDateTo.toString()).format('YYYY-MM-DD')
          : '',
      creationDateFrom:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.creationDateFrom
          ? moment(formData.creationDateFrom.toString()).format('YYYY-MM-DD')
          : '',
      creationDateTo:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? ''
          : formData.creationDateTo
          ? moment(formData.creationDateTo.toString()).format('YYYY-MM-DD')
          : '',
      customer:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.customerNumbers,
      documentStatus:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
              .length > 0
          ? this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
          : [],
      language: 'en',
      searchType: '02',
      serviceType:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.serviceType,
              'serviceType'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.serviceType,
              'serviceType'
            )
          : [],
      socid: this.socNumbers, //soc id
      soId: this.soNumbers, //so id
      spCustomerCode: [
        this.dataShareService.getLocalStorage('customerNumber'),
      ] /**Also known as customerId */,
      technician:
        this.socNumbers.length > 0 || this.soNumbers.length > 0
          ? []
          : this.retriveValueFromMultiselect(
              formData.technicians,
              'technicianId'
            ).length > 0
          ? this.retriveValueFromMultiselect(
              formData.technicians,
              'technicianId'
            )
          : [],
      userId: this.userId,
      userProfile: this.dataShareService.getLocalStorage('role'),
      toBeUpdated: false,
      socDbId: '',
      isConsolidated: false,
    };

    this.dataShareService.setLocalStorage(
      'retriveSocListRequestDto',
      JSON.stringify(data)
    );
    this.httpService.post('claimApi/v1/retrieveSocList', data).subscribe(
      (data) => {
        this.getSOCs();
      },
      (error) => {
        this.loaderService.stop();
      }
    );
  }
  onStartDateChange(startDate: any, rangeInput: any) {
    var date: any = '';
    let minDate: any;
    let maxDate: any = '';
    let selectedStartDate: any = '';
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .updateValueAndValidity();
    if (startDate.value) {
      // minDate = startDate.value;
      selectedStartDate = new Date(startDate.value);
      selectedStartDate.setDate(selectedStartDate.getDate() - 30);
      minDate = selectedStartDate;
      date = new Date(startDate.value);
      date.setDate(date.getDate() + 30);
      if (date > new Date()) {
        maxDate = new Date();
      } else {
        maxDate = date;
      }
    }
    rangeInput.max = maxDate;
    rangeInput.min = minDate;
  }
  clearDate(event: any, rangeInput: any) {
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .setValue('');
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .setValue('');
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .updateValueAndValidity();
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .updateValueAndValidity();
    rangeInput.max = new Date();
    rangeInput.min = '';
  }
}
