import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './services/app.service';
import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewClaimComponent } from './new-claim/new-claim.component';
import { ClaimManagementComponent } from './claim-management/claim-management.component';
import { ConsolidatedClaimComponent } from './consolidated-claim/consolidated-claim.component';
import { LoginComponent } from './login/login.component';
import { SocDetailsComponent } from './claim-management/soc-details/soc-details.component';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
  AlertDialogComponent,
  ErrorAlertDialogComponent,
} from './alert-dialog/alert-dialog.component';
import { AuthInterceptor } from './services/authInterceptor.service';
import { SafePipe } from './pipes/safe.pipe';
import { DocumentDetailsComponent } from './consolidated-claim/document-details/document-details.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import * as Hammer from 'hammerjs';
import {
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: {
      velocity: 0.4,
      threshold: 20,
      direction: Hammer.DIRECTION_HORIZONTAL,
    }, // override default settings
    pinch: { enable: true },
    rotate: { enable: false },
    pan: { enable: false },
  };
  // cssProps= {
  //   userSelect: "auto"
  // }
}
import { DocumentDetailsService } from './consolidated-claim/document-details/document-details.service';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { TooltipComponent } from './wizard/tooltip/tooltip.component';
import { TooltipDirective } from './wizard/tooltip/tooltip.directive';
import { ChipInputComponent } from './common-components/chip-input/chip-input/chip-input.component';

// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { LogsComponent } from './logs/logs.component';
import { SplitPipe } from './pipes/split.pipe';
import { MaxRangeDirective } from './directives/max-range.directive';
import {
  RequestTimeoutHttpInterceptor,
  DEFAULT_TIMEOUT,
} from './services/timeout.interceptor';
import { DaterangepickerDirective } from './custom-directives/daterangepicker.directive';
import { TwoWayRangeDirective } from './services/date-range-picker-selection-strategy';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    NewClaimComponent,
    ClaimManagementComponent,
    ConsolidatedClaimComponent,
    LoginComponent,
    HeaderComponent,
    SocDetailsComponent,
    AlertDialogComponent,
    ErrorAlertDialogComponent,
    SafePipe,
    DocumentDetailsComponent,
    TooltipComponent,
    TooltipDirective,
    ChipInputComponent,
    LogsComponent,
    SplitPipe,
    MaxRangeDirective,
    DaterangepickerDirective,
    TwoWayRangeDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TextFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTabsModule,
    MatCardModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    NgxUiLoaderModule,
    MatExpansionModule,
    MatSortModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgMultiSelectDropDownModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    HammerModule,
    SimpleNotificationsModule.forRoot(),
    NgxMaterialTimepickerModule,
  ],
  providers: [
    AppService,
    HttpErrorHandler,
    MessageService,
    DocumentDetailsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestTimeoutHttpInterceptor,
      multi: true,
    },
    { provide: DEFAULT_TIMEOUT, useValue: 600000 },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Hammer.defaults.cssProps.userSelect = 'auto';
    // console.log=function(){}
  }
}
