<div class="container-fluid" (swipeleft)="onSwipeLeft($event)"
(swiperight)="onSwipeRight($event)" >
    <!-- <div class="pointer mb-2" >
      <button mat-raised-button class=" mr-3 back_btn" (click)="goBack()">
        <span>
          <mat-icon class="align-middle" >arrow_back_ios</mat-icon>
          Back</span>
      </button>

  </div> -->
  <mat-card class="mainFilterCard">
  <div class="row">
      <div class="col-md-4">
        <span>Invoice Id</span>
        <h3>{{consolidatedId}}</h3>
      </div>
      <div class="col-md-4">
        <span>Vendor Id	</span>
        <h3>{{currentData?.vendorId}}	</h3>
      </div>
      <div class="col-md-4">
        <span>Vendor Name	</span>
        <h3>{{currentData?.vendorName}}</h3>
      </div>
      <div class="col-md-4">
        <span>Creation Date	</span>
        <h3>{{currentData?.claimCreationDate | date: 'yyyy-MM-dd'}}</h3>
      </div>
      <div class="col-md-4">
        <span>Status</span>
        <h3>{{currentData?.claimStatus}}</h3>
      </div>
      <div class="col-md-4">
        <span>Total Amount</span>
        <h3>{{currentData?.totalAmount?.toString().endsWith('-')?currentData?.totalAmount: currentData?.totalAmount | number : '1.2'}} {{currentData?.currency}}</h3>
      </div>

  </div>
  </mat-card>


    <ng-container class="row g-2 ">
        <div class="col-12 p-0 mt-3">
          <!-- <div  style="color:#012F60; display:flow-root">
              <div class="float-left p-2"><h4> Consolidated Claim Items </h4></div>
              <div class="float-right p-2" ><button mat-raised-button style="color:#012F60" >Download</button></div>
          </div> -->
          <div class="float-left p-2" style="color:#012F60; display:contents"><h4> Consolidated Claim Items </h4></div>
          <div class="table-actions">

            <div class="btn-action">
            </div>
            <input  class="form-control searchInput" (keyup)="filterSOcs($event)" placeholder="Filter Results..." #input>


            <div class="btn-action mr-1" style="color: white;" >
              <button mat-stroked-button class="btn btn-primary border-0 font-weight-normal" (click)="exportToExcel()"> 
                  <mat-icon>file_download</mat-icon> Download
              </button>
          </div>

          <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" 
          data-toggle="modal" data-target="#exampleModalCenter"  (click)="initialSelect()">
              <mat-icon>filter_alt</mat-icon>
          </button>

            <!-- <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" [matMenuTriggerFor]="menu"
              (click)="initialSelect()"aria-label="Example icon-button with a menu">
              <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button data-toggle="modal" data-target="#exampleModalCenter" mat-menu-item>
                <mat-icon>filter_alt</mat-icon> {{userRole=='SP'?'Search':'Advance Filter'}}
              </button>
              <button mat-menu-item (click)="exportToExcel()">
                <mat-icon>description</mat-icon> Download Sheet
              </button>
            </mat-menu> -->

          </div>

          <section class="example-container mat-elevation-z8" *ngIf="dataSource">
            <div class="custom-table">
            <mat-table #table [dataSource]="dataSource" matSort class="unhoveredTable mb-0">
              <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
                <mat-header-cell *matHeaderCellDef mat-sort-header > {{column.value}} </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-100"> 
                  <span class="mobile-label w-100">{{column.value}}:</span>
                  <span class="w-100 pl-3" *ngIf="column.id!='materialAmount' && column.id !='totalAmount'
                  && column.id !='serviceAmount' && column.id!='docNumber' ">{{element[column.id]}}</span>
                  
                  <!-- [routerLink]="['/home/soc-details',element.socId]" [queryParams]="{for: 'claim'}" -->
                  <span class="w-100 pl-3" *ngIf="column.id =='docNumber'"><a target="_blank" [routerLink]="['/home/soc-details',element.docNumber]" [queryParams]="{for: 'consolidated-claim'}" >{{element[column.id]}}</a></span>

                  <span class="w-100 pl-3" *ngIf="column.id=='materialAmount' || column.id=='totalAmount'
                  || column.id=='serviceAmount'">{{element[column.id]?.toString().endsWith('-') ? element[column.id] : element[column.id] | number : '1.2'}} {{currentData?.currency}}</span>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
            </mat-table>
            </div>
            <mat-paginator  [pageSizeOptions]="[10,20,25]" [showFirstLastButtons]="true">></mat-paginator>
          </section>
        
          </div>
      </ng-container>
  <hr class="w-100">
  <div class="col-12 p-2 " style="margin-bottom: 5em;">
    <button mat-raised-button class="btn-light m-2 text-uppercase float-left"  [disabled]="currentSocIndex<=0" (click)="previousSoc()"> 
      <span class="material-icons-outlined align-middle" style="padding-bottom: 2px">chevron_left</span>
      Back
     </button>

     <button mat-raised-button class="blueBtn m-2 text-uppercase float-right"  (click)="nextSoc()"
      [disabled]="currentSocIndex>=consolidatedClaimList?.length-1"> 
        Next                      
        <span class="material-icons-outlined align-middle"  style="padding-bottom: 2px">chevron_right</span>
     </button>
  </div>
  </div>

    <!-- advanced filter Modal -->
    <div class="modal fade bd-example-modal-lg custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title mb-0" id="exampleModalLongTitle">Detailed Search</h2>
          <h2 class="modal-title mb-0 float-right" style="cursor: pointer"data-dismiss="modal"><mat-icon>close</mat-icon></h2>
        </div>
        <div class="modal-body">
          <mat-tab-group dynamicHeight>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon mr-1">filter_alt</mat-icon>
                <!-- {{userRole=='SP'?'Search':'Advance Filter'}} -->
                Advance Filter
              </ng-template>

              <div class="container-fluid">

                <form [formGroup]="advancedSearchForm" class="row g-2">

                 <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                    <ng-multiselect-dropdown formControlName="doc_no"
                        [placeholder]="'Document Numbers'"
                        [settings]="dropdownSettings"
                        [data]="documentNumbers"> 
                    </ng-multiselect-dropdown>
                 </div>
                 <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                    <ng-multiselect-dropdown formControlName="doc_external_ref"
                        [placeholder]="'External References'"
                        [settings]="dropdownSettings"
                        [data]="externalReferences">
                    </ng-multiselect-dropdown>
                 </div>

              <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <ng-multiselect-dropdown formControlName="doc_ac_ind"
                    [placeholder]="'Accounting Indicators'"
                    [settings]="dropdownSettings"
                    [data]="accountingIndicators">
                </ng-multiselect-dropdown>
              </div>
              <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <ng-multiselect-dropdown formControlName="doc_technicians"
                    [placeholder]="'Technicians'"
                    [settings]="dropdownSettings"
                    [data]="technicians">
                </ng-multiselect-dropdown>
              </div>
              <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <ng-multiselect-dropdown formControlName="doc_service_type"
                  [placeholder]="'Service Types'"
                  [settings]="dropdownSettings"
                  [data]="serviceTypes">
                </ng-multiselect-dropdown>
              </div>

            <div class="col-12 col-md-6 col-sm-6  col-lg-4">
              <ng-multiselect-dropdown formControlName="doc_creadtitmemo"
                [placeholder]="'Credit Memo'"
                [settings]="dropdownSettings"
                [data]="creditMemo">
              </ng-multiselect-dropdown>
            </div>
            <div class="col-12 col-md-6 col-sm-6  col-lg-4">
              <ng-multiselect-dropdown formControlName="doc_total_amount"
                [placeholder]="'Total Amount'"
                [settings]="dropdownSettings"
                [data]="totalAmount">
              </ng-multiselect-dropdown>
            </div>

            </form>

              </div>
              <footer class="text-center mt-5">
                <button type="button" class="btn-light custom-theme-btn btn mr-2" (click)="resetAdvanceFilterForm()">Reset</button>
                <button type="button" class="custom-theme-btn btn btn-primary" data-dismiss="modal"(click)="filterConsolidateClaim()">Search</button>
              </footer>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="material-icons-outlined mr-1">edit</mat-icon>
                Edit Table
              </ng-template>
              <mat-form-field appearance="outline" class="custom-mat-form-field-dropdown">
                <mat-label>Select Column</mat-label>
                <mat-select #select [formControl]="columnFilterControl" multiple>
                  <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip *ngFor="let data of columnFilterControl.value" [removable]="true"
                        (removed)="onColumnRemoved(data)">
                        {{ columnMappingsData[data] }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger>
                  <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                    (change)="toggleAllSelection()" class="custom-mat-checkbox">Select All</mat-checkbox>
                    <ng-container *ngFor="let data of columnsOption">
                      <mat-option *ngIf="data.id != 'docNumber'" (click)="optionClick()"  [value]="data.id">{{data.value}}
                      </mat-option>
                    </ng-container>
                </mat-select>
              </mat-form-field>
              <footer class="text-center mt-5">
                <button type="button" class="btn-light custom-theme-btn btn mr-2" data-dismiss="modal">Cancel</button>
                <button type="button" class="custom-theme-btn btn btn-primary" data-dismiss="modal" (click)="onAdvancedFilterModelClosed()">Save</button>
              </footer>
            </mat-tab>
          </mat-tab-group>
        </div>

      </div>
    </div>
  </div>