<mat-toolbar class="custom-mat-toolbar">
    <section class="toolbar-flex">
        <div class="d-flex align-items-center">
            <button (click)="openSidenav()" mat-icon-button class="example-icon"
                aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
            <div>{{title}}</div>
        </div>

        <div class="d-flex align-items-center">
            <div class="custom-calender" *ngIf="lastFetchTime">
                <span>Last updated:</span>
                <strong>{{lastFetchTime | date: 'yyyy-MM-dd, h:mm a'}}</strong>
                <!-- <mat-icon (click)="refresh()" title="Click to refresh.">refresh</mat-icon> -->
            </div>
            <div class="avatar-group">
                <div class="user-info">
                    <div class="avatar-name">Hi, {{userName}}</div>
                </div>
                <button classs="avatar" mat-icon-button [matMenuTriggerFor]="beforeMenu" type="button"
                    class="example-icon" aria-label="Example icon-button with share icon">
                    <mat-icon>account_circle</mat-icon>
                </button>
            </div>
        </div>

        <mat-menu #beforeMenu>
            <button mat-menu-item (click)="logOut()">
                <mat-icon class="material-icons">
                    power_settings_new
                </mat-icon> <span>Log Out</span>
            </button>
        </mat-menu>
    </section>
</mat-toolbar>