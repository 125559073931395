<div class="container-fluid" (swipeleft)="onSwipeLeft($event)" (swiperight)="onSwipeRight($event)"
  style="user-select: all !important">
  <mat-card class="mainFilterCard">
    <div class="row">
      <div class="col-md-6">
        <span>Service Partner</span>
        <h3>{{currentSocData?.vendorName}}</h3>
      </div>
      <div class="col-md-6">
        <span>Service Confirmation ID</span>
        <h3>
          <span>{{socId}}</span>
          <span>
            <mat-icon *ngIf="isCreditMemoLinked"
              appTooltip="SOC status is no longer editable and that only Service items (ZUKD item category) can be added/modified/removed.">
              info
            </mat-icon>
          </span>
        </h3>
      </div>
    </div>
  </mat-card>
  <div class="">
    <ul class="nav nav-tabs row ml-0 mr-0">
      <ng-container *ngFor="let tabs of userSocMapings?.sectionDetail;let i = index">
        <li class="col-3">
          <a href="#{{tabs.section}}" [ngClass]="{'active': i==0}" data-toggle="tab">
            <span *ngIf="tabs.section=='soc_details'" class="material-icons-outlined">description</span>
            <span *ngIf="tabs.section=='customer_and_model_details'" class="material-icons-outlined">contact_mail</span>
            <span *ngIf="tabs.section=='transaction_history'" class="material-icons-outlined">article</span>
            <span *ngIf="tabs.section=='parties_involved'" class="material-icons-outlined">table_rows</span>

            <h4 class="m-0">{{tabs.sectionName}}</h4>
          </a>
        </li>
      </ng-container>
    </ul>


    <div class="tab-content  panel-group" role="tablist">
      <ng-container *ngFor="let tabs of userSocMapings?.sectionDetail;let j= index">
        <div class="tab-pane" [ngClass]="{'active':j==0}" id="{{tabs.section}}">
          <div class="panel panel-default ">
            <div class="panel-heading " [ngClass]="{'active':j==0}">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent=".tab-pane" href="#a{{tabs.section}}"
                  attr.aria-expanded="{{j==0?true:false}}">
                  <span *ngIf="tabs.section=='soc_details'" class="material-icons-outlined">description</span>
                  <span *ngIf="tabs.section=='customer_and_model_details'"
                    class="material-icons-outlined">contact_mail</span>
                  <span *ngIf="tabs.section=='transaction_history'" class="material-icons-outlined">article</span>
                  <span *ngIf="tabs.section=='parties_involved'" class="material-icons-outlined">table_rows</span>
                  <span class="acc_name pl-2">{{tabs.sectionName}}</span>
                </a>
              </h4>
            </div>
            <div id="a{{tabs.section}}" class="panel-collapse collapse in" [ngClass]="{'show': j==0}">
              <div class="panel-body">
                <mat-card>
                  <ng-container *ngIf="tabs.section!='transaction_history' && tabs.section!='parties_involved'">
                    <form class="row g-2" [formGroup]="serviceConfirmForm">
                      <ng-container *ngFor="let fields of tabs.fieldDetails;let k = index">
                        <ng-container *ngIf="fields.field!='item' && fields.field!='notes'">
                          <div class="col-12" style="color:#012F60 ">
                            <div class="p-2">
                              <h4> {{fields.fieldName}} </h4>
                            </div>
                          </div>
                          <ng-container *ngFor="let data of fields.subFieldDetail">

                            <div class="col-12 col-md-6 col-sm-6 col-lg-3" *ngIf="
                              data?.subField != 'currency' &&
                              !data.subField.includes('Date') &&
                              data?.subField != 'customerSurname' &&
                              data?.subField != 'totalAmount' &&
                              data?.subField != 'serviceType'
                            ">
                              <ng-container>
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                  <mat-label>{{data?.subFieldName}}</mat-label>
                                  <input matInput placeholder="Enter {{data?.subFieldName}}" [value]="data?.value"
                                    type="text" disabled>
                                </mat-form-field>
                              </ng-container>
                            </div>
                            <div class="col-12 col-md-6 col-sm-6 col-lg-3"
                              *ngIf="data?.subField.includes('serviceType')">
                              <ng-container>
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                  <mat-label>{{ data?.subFieldName }}</mat-label>
                                  <mat-select formControlName="serviceType">
                                   <mat-option *ngFor="let servicetype of serviceTypeList" [value]="servicetype.serviceTypeName">
                                   {{servicetype.serviceTypeName}}
                                  </mat-option>
                                  </mat-select>
                                 
                                  <!-- <input formControlName="serviceType" matInput
                                    placeholder="Enter {{ data?.subFieldName }}" [value]="data?.value" type="text"
                                    
                                    /> -->
                                </mat-form-field>
                              </ng-container>
                            </div>
                            <div class="col-12 col-md-6 col-sm-6 col-lg-3" *ngIf="data.subField.includes('Date')">
                              <ng-container>
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                  <mat-label>{{data?.subFieldName}}</mat-label>
                                  <input matInput placeholder="Enter {{data?.subFieldName}}"
                                    [value]="data?.value | date: 'yyyy-MM-dd'" type="text" disabled>
                                </mat-form-field>
                              </ng-container>
                            </div>
                            <div class="col-12 col-md-6 col-sm-6 col-lg-3" *ngIf="data.subField=='totalAmount'">
                              <ng-container>
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                  <mat-label>{{data?.subFieldName}} </mat-label>
                                  <input matInput placeholder="Enter {{data?.subFieldName}}"
                                    value="{{data?.value?.toString().endsWith('-')? data?.value: data?.value | number : '1.2'}} {{currency}}"
                                    type="text" disabled>
                                </mat-form-field>
                              </ng-container>
                            </div>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="fields.field=='notes'">
                          <div class='col-12 mt-3'>
                            <div style="color:#012F60; display:flow-root">
                              <div class="float-left p-2">
                                <h4> Notes </h4>
                              </div>
                            </div>
                            <section class="example-container mat-elevation-z8">
                              <div class="custom-table">
                                <mat-table #table [dataSource]="dataSource" class="unhoveredTable mb-0 noteTable">
                                  <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
                                    <mat-header-cell *matHeaderCellDef> {{column.value}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element; let i = index;" class="w-100 mb-auto">
                                      <span class="mobile-label w-100">{{column.value}}:</span>
                                      <span class="w-100 pl-3" *ngIf="column.id =='noteTdText'">
                                        {{element[column.id][0]}}
                                      </span>
                                      <span class="w-100 pl-3" *ngIf="column.id !='id' && column.id !='noteTdText'">
                                        <p *ngFor="let text of element[column.id]">
                                          {{text}}
                                        </p>
                                      </span>
                                      <span class="w-100 pl-3" *ngIf="column.id =='id'">{{i+1}}</span>

                                      <!-- <span class="w-100 pl-3" *ngIf="column.id =='action'" (click)="deleteNote()"><mat-icon>delete</mat-icon> </span>
                                    <span class="w-100 pl-3" *ngIf="column.id =='action'" (click)="editNote()" data-toggle="modal"
                                          data-target="#editNoteModal" class="ml-2"><mat-icon>edit</mat-icon> 
                                    </span> -->
                                    </mat-cell>
                                  </ng-container>
                                  <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
                                </mat-table>
                              </div>
                              <mat-paginator #notePaginator="matPaginator" [pageSizeOptions]="[10,20,25]"
                                [showFirstLastButtons]="true">></mat-paginator>
                            </section>
                          </div>
                        </ng-container>


                      </ng-container>

                    </form>
                  </ng-container>

                  <ng-container *ngIf="tabs.section=='transaction_history' ">
                    <div style="color:#012F60; display:flow-root">
                      <div class="float-left p-2">
                        <h4> Transaction History </h4>
                      </div>
                    </div>
                    <section class="example-container mat-elevation-z8">
                      <div class="custom-table">
                        <mat-table #table [dataSource]="transactionDataSource" #matTransactionSort="matSort" matSort
                          class="unhoveredTable mb-0">
                          <ng-container [matColumnDef]="column.id" *ngFor="let column of transactioDisplayedColumns">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.value}} </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="w-100">
                              <span class="mobile-label w-100">{{column.value}}:</span>
                              <span class="w-100 pl-3" *ngIf="column.id.includes('Date')">{{element[column.id] }}</span>

                              <span class="w-100 pl-3"
                                *ngIf="column.id !='action' && !column.id.includes('Date')">{{element[column.id]}}</span>
                              <span class="w-100 pl-3" *ngIf="column.id =='action'"><mat-icon>delete</mat-icon> </span>
                              <span class="w-100 pl-3" *ngIf="column.id =='action'"
                                class="ml-2"><mat-icon>edit</mat-icon> </span>
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="transactioColumnsToDisplay; sticky:true"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: transactioColumnsToDisplay;"></mat-row>
                        </mat-table>
                      </div>
                      <mat-paginator #transactionPaginator="matPaginator" [pageSizeOptions]="[10,20,25]"
                        [showFirstLastButtons]="true">></mat-paginator>
                    </section>
                  </ng-container>
                  <ng-container *ngIf="tabs.section=='parties_involved'">
                    <div style="color:#012F60; display:flow-root">
                      <div class="float-left p-2">
                        <h4> Parties Involved </h4>
                      </div>
                    </div>

                    <section class="example-container mat-elevation-z8">
                      <div class="custom-table">
                        <mat-table #table [dataSource]="partiesDataSource" #matPartyInvolvedSort="matSort" matSort
                          class="unhoveredTable mb-0">
                          <ng-container [matColumnDef]="column.id" *ngFor="let column of partiesDisplayedColumns">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.value}} </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="w-100">
                              <span class="mobile-label w-100">{{column.value}}:</span>

                              <span class="w-100 pl-3" *ngIf="column.id !='action'">{{element[column.id]}}</span>
                              <span class="w-100 pl-3" *ngIf="column.id =='action'"><mat-icon>delete</mat-icon> </span>
                              <span class="w-100 pl-3" *ngIf="column.id =='action'"
                                class="ml-2"><mat-icon>edit</mat-icon> </span>
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="partiesColumnsToDisplay; sticky:true"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: partiesColumnsToDisplay;"></mat-row>
                        </mat-table>
                      </div>
                      <mat-paginator #partiesPaginator="matPaginator" [pageSizeOptions]="[10,20,25]"
                        [showFirstLastButtons]="true">></mat-paginator>
                    </section>

                  </ng-container>

                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <mat-card class="mt-3" *ngIf="userSocMapings?.sectionDetail">
        <ng-container class="row">
          <div class="col-12 p-0">
            <div style="color:#012F60;display:flow-root" class="pr-1">
              <div class="float-left p-2">
                <h4 class="m-0"> Item Details </h4>
              </div>
              <div class="float-right ">
                <button *ngIf="isEditableSoc" mat-raised-button class="pl-1" mat-raised-button style="color:#012F60 "
                  (click)="addItem()" data-toggle="modal" data-target="#editItemModal">
                  <span class="material-icons-outlined align-middle pb-1">add</span> Add Item
                </button>
              </div>
            </div>

            <section class="example-container mat-elevation-z8">
              <div class="custom-table">
                <mat-table #table [dataSource]="itemDataSource" #matItemSort="matSort" matSort class="table mb-0">
                  <ng-container [matColumnDef]="column.id" *ngFor="let column of itemDisplayedColumns">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.id=='action' ? true : false ">
                      {{column.value}} </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i=index" class="w-100">
                      <span class="mobile-label w-100">{{column.value}}:</span>

                      <span class="w-100 pl-3"
                        *ngIf="column.id !='action' && column.id !='itemPrice'">{{element[column.id]}}</span>
                      <span class="w-100 pl-3"
                        *ngIf="column.id =='itemPrice'">{{element[column.id]?.toString().endsWith('-')?
                        element[column.id]:element[column.id] | number : '1.2'}} {{element['currency']}}</span>
                      <span class="w-100 pl-3" *ngIf="column.id =='action'">
                        <ng-container *ngIf="!isCreditMemoLinked && isEditableSoc">
                          <span class="ml-2 float-left"
                            *ngIf="element?.isLocallyAddedItem || (!element['itemCode']?.toLowerCase().includes('zsvp') && ((element['productId']?.toLowerCase().includes('travel') || element['productId']?.toLowerCase().includes('addons')) || (element['itemCode']?.toLowerCase().includes('zvcm'))))"
                            (click)="editItem(element,i)" data-toggle="modal"
                            data-target="#editItemModal"><mat-icon>edit</mat-icon></span>
                          <span class="ml-2 float-right" *ngIf="!element['itemCode']?.toLowerCase().includes('zsvp')"
                            (click)="deleteItem(element,i)"><mat-icon>delete</mat-icon></span>
                        </ng-container>

                        <ng-container *ngIf="isCreditMemoLinked && isEditableSoc">
                          <span class="ml-2"
                            *ngIf="element.itemCode == 'ZUKD' && (element?.isLocallyAddedItem || (!element['itemCode']?.toLowerCase().includes('zsvp') && (element['productId']?.toLowerCase().includes('travel') ||element['productId']?.toLowerCase().includes('addons')) ))"
                            (click)="editItem(element,i)" data-toggle="modal"
                            data-target="#editItemModal"><mat-icon>edit</mat-icon></span>
                          <span class="ml-2 float-right"
                            *ngIf="element.itemCode == 'ZUKD' && (element?.isLocallyAddedItem || (!element['itemCode']?.toLowerCase().includes('zsvp') && (element['productId']?.toLowerCase().includes('travel') ||element['productId']?.toLowerCase().includes('addons')) ))"
                            (click)="deleteItem(element,i)"><mat-icon>delete</mat-icon></span>
                          <span class="ml-2" *ngIf="element.itemCode != 'ZUKD'"><mat-icon>block</mat-icon></span>
                        </ng-container>
                      </span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="itemColumnsToDisplay; sticky:true"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: itemColumnsToDisplay;"></mat-row>
                </mat-table>
              </div>
              <mat-paginator #itemPaginator="matPaginator" [pageSizeOptions]="[10,20,25]"
                [showFirstLastButtons]="true">></mat-paginator>
            </section>

          </div>


          <ng-container>
            <ng-container *ngIf="userRole=='SP' && isEditableSoc && !isCreditMemoLinked">
              <div class="col-12 p-2 text-right">
                <button mat-raised-button class="yellowBtn m-2 text-uppercase socBtn"
                  (click)="statusUpdate('revalidate')" data-toggle="modal" data-target="#addNoteModal">
                  <span class="material-icons-outlined">
                    verified
                  </span>
                  Revalidate
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="(userRole=='DM' || userRole=='AM') && isEditableSoc">
              <div class="col-12 p-2 statusBtns ">
                <button mat-raised-button *ngIf="!isCreditMemoLinked && selectedStatus.socStatus=='TVM'"
                  class="greenBtn m-2 text-uppercase socBtn" (click)="statusUpdate('accept')"
                  [disabled]="itemsToBeUpdate.length>0 && tvmDisabled">
                  <span class="material-icons-outlined">thumb_up</span>
                  Accept
                </button>
                <button mat-raised-button
                  *ngIf="!isCreditMemoLinked && (selectedStatus.socStatus=='TVM' || selectedStatus.socStatus=='AMA' || selectedStatus.socStatus=='AUT')"
                  class="redBtn m-2 text-uppercase socBtn" (click)="statusUpdate('reject')"
                  [disabled]="itemsToBeUpdate.length>0">
                  <span class="material-icons-outlined">thumb_down_alt</span>
                  Reject
                </button>
                <button mat-raised-button *ngIf="!isCreditMemoLinked && selectedStatus.socStatus=='TVM'"
                  class="blueBtn m-2 text-uppercase socBtn" (click)="statusUpdate('send_to_sp')" data-toggle="modal"
                  data-target="#addNoteModal" [disabled]="itemsToBeUpdate.length>0">
                  <span class="material-icons-outlined">send</span>
                  Send To SP
                </button>
                <button mat-raised-button
                  *ngIf="!isCreditMemoLinked && (selectedStatus.socStatus=='TVM' || selectedStatus.socStatus=='AMA' || selectedStatus.socStatus=='AUT')"
                  class="yellowBtn m-2 text-uppercase socBtn" (click)="statusUpdate('revalidate')">
                  <span class="material-icons-outlined">verified</span>
                  Revalidate
                </button>
                <button mat-raised-button
                  *ngIf="!isCreditMemoLinked && (selectedStatus.socStatus=='AUT' || selectedStatus.socStatus=='AMA')"
                  class="greenBtn m-2 text-uppercase socBtn" (click)="statusUpdate('save')"
                  [disabled]="itemsToBeUpdate.length>0 && saveDisabled">
                  <span class="material-icons-outlined align-middle pb-1">save</span>
                  Save
                </button>
                <button mat-raised-button *ngIf="isCreditMemoLinked && selectedStatus.socStatus=='AUT'"
                  class="greenBtn m-2 text-uppercase socBtn" (click)="statusUpdate('save_with_creaditmemo')">
                  <span class="material-icons-outlined align-middle pb-1">save</span>
                  Save
                </button>
              </div>
            </ng-container>
            <p></p>
            <ng-container *ngIf="itemsToBeUpdate.length>0 && !isCreditMemoLinked">
              <small class="restricted-highlight w-100 text-right pr-3" *ngIf="saveDisabled">
                You have added/deleted/updated the item. Please <b>{{tvmDisabled?'"REVALIDATE"':'"ACCEPT" OR
                  "REVALIDATE"'}}</b> to save the changes or
                click <button mat-raised-button class="blueBtn m-1 text-uppercase p-0"
                  (click)="resetItems()">Reset</button> to reset the items.
              </small>
              <small class="restricted-highlight w-100 text-right pr-3" *ngIf="!saveDisabled">
                You have added/deleted/updated the item. Please <b>"REVALIDATE" OR "SAVE"</b> to save the changes or
                click <button mat-raised-button class="blueBtn m-1 text-uppercase p-0"
                  (click)="resetItems()">Reset</button> to reset the items.
              </small>
            </ng-container>
            <ng-container *ngIf="itemsToBeUpdate.length>0 && isCreditMemoLinked">
              <small class="restricted-highlight w-100 text-right pr-3">
                You have added/deleted/updated the item. Please <b>"SAVE"</b> to save the changes or
                click <button mat-raised-button class="blueBtn m-1 text-uppercase p-0"
                  (click)="resetItems()">Reset</button> to reset the items.
              </small>
            </ng-container>
          </ng-container>


          <div class="col-12 p-2 disabled d-inline-block" *ngIf="routParam!='consolidated-claim'">
            <hr class="w-100">
            <button mat-raised-button class="btn-light m-2 text-uppercase float-left" (click)="previousSoc()"
              [disabled]="currentSocIndex<=0">
              <span class="material-icons-outlined align-middle" style="padding-bottom: 2px">chevron_left</span>
              Back
            </button>

            <button mat-raised-button class="blueBtn m-2 text-uppercase float-right" (click)="nextSoc()"
              [disabled]="currentSocIndex>=socListData?.length-1">
              Next
              <span class="material-icons-outlined align-middle" style="padding-bottom: 2px">chevron_right</span>
            </button>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </div>

</div>

<!-- Add Note Modal -->
<div class="modal fade bd-example-modal-lg custom-modal" id="addNoteModal" tabindex="-1" role="dialog"
  aria-labelledby="addNoteModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mb-0 float-left">Add Notes</h2>
        <h2 class="modal-title mb-0 float-right pointer" data-dismiss="modal">
          <mat-icon>close</mat-icon>
        </h2>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="addNoteForm" class="row g-2">
            <div class="col-12">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-label>Notes</mat-label>
                <textarea matInput placeholder="Enter text" formControlName='text' required></textarea>
                <mat-error>
                  <mat-icon class="material-icons-round mr-1">warning</mat-icon>
                  *This is the required field.
                </mat-error>

              </mat-form-field>
            </div>
          </form>
        </div>
        <footer class="text-center mt-5">
          <button mat-raised-button class="whiteBtn mr-3" data-dismiss="modal">Close</button>
          <button mat-raised-button class="blueBtn" type='submit' data-dismiss="modal" (click)="addNote()"
            [disabled]="!addNoteForm.valid">Save
          </button>
        </footer>
      </div>
    </div>
  </div>
</div>


<!-- Add/Edit Item Modal -->
<div class="modal fade bd-example-modal-lg custom-modal" id="editItemModal" tabindex="-1" role="dialog"
  aria-labelledby="addNoteModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 *ngIf="isEditPoupUp" class="modal-title mb-0 float-left">Edit Item</h2>
        <h2 *ngIf="!isEditPoupUp" class="modal-title mb-0 float-left">Add Item</h2>
        <h2 class="modal-title mb-0 float-right pointer" data-dismiss="modal">
          <mat-icon>close</mat-icon>
        </h2>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="addEditItemForm" class="row g-2">

            <div class="col-12 col-md-6 col-lg-6" *ngIf="isEditPoupUp">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Product Id</mat-label>
                <mat-select formControlName="product_id" (selectionChange)="selectProduct(selectedProduct)"
                  [(ngModel)]='selectedProduct' [disabled]="isEditPoupUp && !selectedItem?.isLocallyAddedItem" required>
                  <mat-option *ngFor="let data of filteredSocItemProductList" [value]="data">
                    {{data.productId}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-6" *ngIf="!isEditPoupUp">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Product Id</mat-label>
                <mat-select formControlName="product_id" (selectionChange)="selectProduct(selectedProduct)"
                  [(ngModel)]='selectedProduct' [disabled]="isEditPoupUp && !selectedItem?.isLocallyAddedItem" required>
                  <mat-option *ngFor="let data of filteredSocItemProductList" [value]="data">
                    {{data.productId}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-6" *ngIf="isSparePartProduct">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Spare Part</mat-label>
                <input type="text" placeholder="Search and select product" aria-label="Product" matInput
                  formControlName="sparePartProductControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredsparePartProduct" [value]="option.productDescription"
                    (click)="onSparePartProductSelection(option)">
                    {{option.productDescription}}
                  </mat-option>
                  <mat-option *ngIf="addEditItemForm.get('sparePartProductControl').value.length<5" disabled>
                    Please enter at least 5 characters.
                  </mat-option>
                  <mat-option
                    *ngIf="addEditItemForm.get('sparePartProductControl').value.length>=5 && filteredsparePartProduct.length<=0"
                    disabled>
                    No Results Found!
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Item Category</mat-label>
                <mat-select formControlName="item_category" [(ngModel)]='selectedProductItem'
                  (selectionChange)="selectProductItem(selectedProductItem)"
                  [disabled]="isEditPoupUp && !selectedItem?.isLocallyAddedItem" required>
                  <mat-option *ngFor="let data of selectedProduct.itemCategory" [value]="data">
                    {{data | split}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Quantity</mat-label>
                <input matInput placeholder="Enter quantity" formControlName="quantity" type="number" min="0"
                  [(ngModel)]="selectedProduct.quantity" (change)="quantityChange(selectedProduct)" required>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-6" *ngIf="selectedProduct.price!='Calculated in CRM' ">
              <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Price</mat-label>
                <input matInput placeholder="Enter price" formControlName="price" type="text"
                  [ngModel]="selectedProduct.price" (ngModelChange)="selectedProduct.price=$event">
                <mat-labe matSuffix>{{currency}}</mat-labe>
              </mat-form-field>
            </div>
          </form>
        </div>
        <small *ngIf="isCreditMemoLinked && !creaditLinkedAndZukdItem" class="restricted-highlight ">
          *SOC already linked to Credit memo, you can only add ZUKD item category.
        </small>
        <footer class="text-center mt-5">
          <button mat-raised-button class="whiteBtn mr-3" data-dismiss="modal">Close</button>
          <button mat-raised-button class="blueBtn" data-dismiss="modal" (click)="updateItemSubmit()"
            [disabled]="!addEditItemForm.valid"
            *ngIf="(!isCreditMemoLinked) || (isCreditMemoLinked && creaditLinkedAndZukdItem)">Save</button>
        </footer>
      </div>
    </div>
  </div>
</div>