import { Injectable } from '@angular/core';
import {
  HttpErrorHandler,
  HandleError,
} from '../../services/http-error-handler.service';
import { HttpService } from '../../services/http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import 'hammerjs';
import 'hammer-timejs';
@Injectable()
export class DocumentDetailsService {
  preferencesUrl = 'columnPreferenceApi/v1/setUserColumnPreferences?';
  handleError: HandleError;
  constructor(
    private httpService: HttpService,
    private loaderService: NgxUiLoaderService,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'DocumentDetailsService'
    );
  }

  /** POST: add a new column preferences to the database */
  setColumnsPreferences(queryParam: string): void {
    this.httpService.post(this.preferencesUrl + queryParam).subscribe(
      (res: any) => {
        this.loaderService.stop();
        console.log(res);
      },
      (err) => {
        this.loaderService.stop();
        console.log(err);
      }
    );
  }
}
