function makeAppConfig() {
  // let  env = 'dev';  //Change this for [ dev | uat | prod ] release
  let AppConfig = {
    // URL: 'https://europe-backend-dot-whp-sp-portal-prod.ew.r.appspot.com', //For prod
    // serviceDocUrl: 'http://wp-documents.europeanappliances.com', //prod
    URL: 'https://europe-backend-dot-whp-sp-portal-dev.ew.r.appspot.com', //For dev
    serviceDocUrl: 'https://wp-documentsqa.europeanappliances.com', //dev
    apiKey: 'AIzaSyCCMDnRCxHGZslRo9q1v3edr4eHprnkoZ8',
    key: 'AIzaSyCCMDnRCxHGZslRo9q1v3edr4eHprnkoZ8',
    language: 'EN',
    emailPattern: /^.+@.+\..+$/,
  };
  return AppConfig;
}
export const APPCONFIG = makeAppConfig();
