import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { StaticData } from '../../shared/staticData';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from '../../services/app.service';
import { HttpService } from '../../services/http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from '../../alert-dialog/alert.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
declare var $: any;
import 'hammerjs';
import 'hammer-timejs';
import { DataShareService } from '../../services/data-share.service';
import { DOWN_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
@Component({
  selector: 'app-soc-details',
  templateUrl: './soc-details.component.html',
  styleUrls: ['./soc-details.component.scss'],
})
export class SocDetailsComponent implements OnInit, AfterViewInit {
  @Input() socDetail: any;
  addNoteForm: any;
  @ViewChild('matItemSort') matItemSort: MatSort | any;
  @ViewChild('matNotesSort') matNotesSort: MatSort | any;
  @ViewChild('matTransactionSort') matTransactionSort: MatSort | any;
  @ViewChild('matPartyInvolvedSort') matPartyInvolvedSort: MatSort | any;
  displayedColumns = StaticData.notesTableCoulumn;
  dataSource: any;
  columnsToDisplay: any = [];
  socId: any;
  pageType = 'claim_management';
  transactioDisplayedColumns = StaticData.transactionTableCoulumn;
  transactionDataSource: any;
  transactioColumnsToDisplay: any = [];
  partiesDisplayedColumns = StaticData.partiesTableCoulumn;
  partiesDataSource: any;
  partiesColumnsToDisplay: any = [];
  itemDisplayedColumns: any = StaticData.itemTableCoulumn;
  itemDataSource: any;
  itemColumnsToDisplay: any = [];
  dropdownSettings = {};
  status: any = [];
  itemCategory: any;
  serviceType: { id: string; value: string }[];
  textType: any;
  date = new FormControl(new Date());
  userSocMapings: any;
  selectedStatus: any;
  isEditableSoc = false;
  userId: any = '';
  masterData: any;
  selectedProductItem: any;
  addEditItemForm: any;
  isEditPoupUp = true;
  socItemProductList: any = [];
  selectedProduct: any = { price: '' };
  userRole: any;
  currentAnimationState: any;
  isCreditMemoLinked: boolean = false;
  isZUKDCategoryExists: any;
  creaditLinkedAndZukdItem = false;
  currentSocIndex: any;
  editableSocNames: any = [];
  token: any;
  selectedItem: any;
  currency: any;
  itemsToBeUpdate: any = [];
  filteredsparePartProduct: any = [];
  isSparePartProduct: boolean = false;
  selectedSparePartProduct: any;
  selectedItemIndex: any;
  selectedItemCurrency: any;
  filteredSocItemProductList: any = [];
  serviceTypeList:any = [];
  saveDisabled: boolean = false;
  tvmDisabled: boolean = false;
  @ViewChild('notePaginator') notePaginator: MatPaginator | any;
  @ViewChild('itemPaginator') itemPaginator: MatPaginator | any;
  @ViewChild('transactionPaginator') transactionPaginator: MatPaginator | any;
  @ViewChild('partiesPaginator') partiesPaginator: MatPaginator | any;
  currentSocAction: any;
  arc = 'false';
  socListData: any;
  currentSocData: any;
  routParam: any = '';
  innerWidth: any = 0;
  getDataSocdetails: any;
  receivedData: any;
  receivedSocData: any;
  currentSocId: any;
  serviceConfirmForm: any;
  serviceTypeValue: any = '';
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private http: HttpService,
    private loaderService: NgxUiLoaderService,
    private commonService: CommonService,
    private dataShareService: DataShareService
  ) {
    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');
    this.currentSocId = this.dataShareService.getLocalStorage('currentSocId');
    this.appService.setTitle('Claim Management');
    this.activatedRoute.queryParamMap.subscribe(
      (params) => (this.routParam = params.get('for'))
    );

    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');
    this.token = this.dataShareService.getLocalStorage('userToken');
    this.socId = this.activatedRoute.snapshot.paramMap.get('id');
    this.currentSocId = this.dataShareService.getLocalStorage('currentSocId');
    this.itemCategory = StaticData.itemCategory;
    this.serviceType = StaticData.serviceType;
    this.textType = StaticData.textType;
    this.addNoteForm = this.formBuilder.group({
      text: ['', [Validators.required]],
    });
    this.addEditItemForm = this.formBuilder.group({
      product_id: ['', [Validators.required]],
      item_category: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      price: [''],
      sparePartProductControl: [''],
    });
    this.displayedColumns.forEach((element) => {
      this.columnsToDisplay.push(element.id);
    });
    this.transactioDisplayedColumns.forEach((element) => {
      this.transactioColumnsToDisplay.push(element.id);
    });
    this.partiesDisplayedColumns.forEach((element) => {
      this.partiesColumnsToDisplay.push(element.id);
    });
    this.itemDisplayedColumns.forEach((element: any) => {
      this.itemColumnsToDisplay.push(element.id);
    });
    this.isZUKDCategoryExists = undefined;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  resSocData: any;
  listSocData: any;
  ngOnInit(): void {
    console.log('Response Soc Data', this.resSocData);
    this.dataShareService.currentFormData.subscribe((jsonString) => {
      this.receivedData = JSON.parse(jsonString);
      console.log('Retrieve data:', this.receivedData);
    });
    this.dataShareService.currentFormSocData.subscribe((getSocData) => {
      this.listSocData = JSON.parse(getSocData);
      console.log('listSocData' + this.listSocData);
      this.listSocData.forEach((element: any) => {
        console.log('element' + element);
        if (element.socId === this.socId) {
          this.resSocData = element;
        }
      });
      console.log('Recieved from SOC deatils data:', this.resSocData);
    });
    this.innerWidth = window.innerWidth;
    this.addEditItemForm
      .get('sparePartProductControl')
      .valueChanges.subscribe((changeValue: any) => {
        if (changeValue && changeValue.length >= 5) {
          this.getSparePartProducts(changeValue);
        }
      });
    this.addEditItemForm
      .get('product_id')
      .valueChanges.subscribe((value: any) => {
        this.selectedItemCurrency = value.currency;
        this.creaditLinkedAndZukdItem = false;
        if (value && value.itemCategory) {
          if (this.isCreditMemoLinked) {
            if (value.itemCategory[0].startsWith('ZUKD')) {
              this.creaditLinkedAndZukdItem = true;
            } else {
              this.creaditLinkedAndZukdItem = false;
            }
          }
        }
        if (
          value['productId'] &&
          value['productId'].toLowerCase().includes('spare part')
        ) {
          this.isSparePartProduct = true;
          this.addEditItemForm
            .get('sparePartProductControl')
            .setValidators([Validators.required]);
          this.addEditItemForm
            .get('sparePartProductControl')
            .updateValueAndValidity();
        } else {
          this.isSparePartProduct = false;
          this.addEditItemForm.get('sparePartProductControl').clearValidators();
          this.addEditItemForm
            .get('sparePartProductControl')
            .updateValueAndValidity();
        }
      });

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.getUserMasterData(this.pageType);
    if (this.routParam !== 'consolidated-claim') {
    this.retriveSocList();
    }
    this.serviceConfirmForm = new FormGroup({
      serviceType: new FormControl({value: '', disabled: true}, Validators.required),
    });
   
    this.serviceConfirmForm
      .get('serviceType')
      .valueChanges.subscribe((value: string) => {
        console.log('Value', value);
          for(let i =0;i<this.serviceTypeList.length;i++){
               if(this.serviceTypeList[i].serviceTypeName == value){
                this.serviceTypeValue = this.serviceTypeList[i].serviceType
               }
          }
        console.log('this.serviceTypeValue', this.serviceTypeValue);
      });
    
  }
  ngAfterViewInit() {}
  getSparePartProducts(characters: any) {
    this.http
      .get('columnPreferenceApi/v1/getSpareMaster?spare=' + characters)
      .subscribe(
        (data: any) => {
          if (data.status != 'ERROR' && data.response.length > 0) {
            this.filteredsparePartProduct =
              data.response.length < 100
                ? data.response.slice(0, data.response.length)
                : data.response.slice(0, 100); //data.response
          } else {
            this.filteredsparePartProduct = [];
          }
        },
        (error) => {}
      );
  }
  goBack() {
    this.router.navigate(['home/claim-management'], {
      state: { data: 'fromsocDetail' },
    });
  }
  getUserMasterData(pageType: any) {
    this.loaderService.start();
    this.http
      .get(
        'userApi/v1/getUserMasterData?code=' +
          this.dataShareService.getLocalStorage('role') +
          '&page_type=' +
          pageType
      )
      .subscribe(
        (data: any) => {
          this.masterData = data.response;
          this.serviceTypeList = data.response.serviceDetail
          this.status = this.masterData.socStatusDetail;
          this.getUserSocMapping();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  getUserSocMapping() {
    const code = this.dataShareService.getLocalStorage('role');
    this.http
      .get(
        'claimApi/v1/getUserSOCMapping?action=soc_detail&page_type=claim_management&code=' +
          code
      )
      .subscribe(
        (data: any) => {
          this.userSocMapings = data.response;
          const sectionDetail: any = [];
          this.userSocMapings.sectionDetail.forEach((element: any) => {
            if (element.section == 'soc_details') {
              const fieldDetails: any = [];
              element.fieldDetails.forEach((subfield: any) => {
                if (subfield.field == 'header_data') {
                  fieldDetails.unshift(subfield);
                } else if (subfield.field == 'notes') {
                  fieldDetails.splice(1, 0, subfield);
                } else {
                  fieldDetails.push(subfield);
                }
              });
              element.fieldDetails = fieldDetails;
              sectionDetail.unshift(element);
            } else if (element.section == 'customer_and_model_details') {
              const fieldDetails: any = [];
              element.fieldDetails.forEach((subfield: any) => {
                if (subfield.field == 'customer') {
                  fieldDetails.unshift(subfield);
                } else {
                  fieldDetails.push(subfield);
                }
              });
              element.fieldDetails = fieldDetails;
              sectionDetail.splice(1, 0, element);
            } else {
              sectionDetail.push(element);
            }
          });
          this.userSocMapings.sectionDetail = sectionDetail;
          this.getEditableSocs(this.userSocMapings);
        },
        (err) => {
          this.loaderService.stop();
        }
      );
  }

  getEditableSocs(userSocMapings: any) {
    this.editableSocNames = [];
    this.masterData.socStatusDetail.forEach((element: any) => {
      if (userSocMapings.editableSOC.includes(element.socStatus)) {
        this.editableSocNames.push(element.socStatusName);
      }
    });
    if (this.routParam == 'consolidated-claim') {
      this.retriveSocListForConsolidated();
    } else {
      // this.getSOCs();
    }
  }

  getSocListData(socList: any) {
    this.loaderService.stop();
    this.socListData = socList;
    let socDetailExist = true;
    if (this.socListData.length <= 0) {
      this.router.navigate(['home/claim-management']);
    } else {
      for (let i = 0; i < this.socListData.length; i++) {
        socDetailExist = false;
        console.log("docnumber : "+this.socListData[i].docNumber );
        if (this.socListData[i].docNumber == this.socId) {
          this.currentSocData = this.socListData[i];
          this.dataShareService.setLocalStorage(
            'currentSocId',
            this.currentSocData.socId
          );
          this.currentSocIndex = i;
          console.log("index : "+this.currentSocIndex);
          this.currency = this.currentSocData.itemDetail
            ? this.currentSocData.itemDetail[0].currency
            : '';
          this.checkForCreaditmemolinked(); /** check if credit memo link*/
          socDetailExist = true;
          break;
        }
      }
      if (!socDetailExist) {
        if (this.currentSocIndex < this.socListData?.length - 1) {
          this.nextSoc();
        } else if (this.currentSocIndex > 0) {
          this.previousSoc();
        } else {
          this.router.navigate(['home/claim-management']);
        }
        return;
      }
      this.reArrangeNotes();
      this.getSocItems();
      this.setCurrentStatus(this.currentSocData);
      if(this.isEditableSoc){
      this.serviceConfirmForm.get('serviceType').enable();
      }else{
        this.serviceConfirmForm.get('serviceType').disable();
      }
      this.serviceConfirmForm.get('serviceType').setValue(this.currentSocData.serviceType);
      this.getSocDetailsTablesData(this.currentSocData);
    }
  }
  checkForCreaditmemolinked() {
    if (
      (this.userRole == 'DM' || this.userRole == 'AM') &&
      this.currentSocData.transactionHistory
    ) {
      for (let i = 0; i < this.currentSocData.transactionHistory.length; i++) {
        if (this.currentSocData.transactionHistory[i].docType == 'BUS2094') {
          this.isCreditMemoLinked = true;
          break;
        }
      }
    } else {
      this.isCreditMemoLinked = false;
    }
  }
  reArrangeNotes() {
    let _tempNotes: any = [];
    if (this.currentSocData) {
      for (const property in this.currentSocData.notes) {
        if (property == 'ZE01' || property == 'ZE02') {
          _tempNotes.unshift(this.currentSocData.notes[property]);
        } else {
          _tempNotes.push(this.currentSocData.notes[property]);
        }
      }
    }
    this.currentSocData.notes = _tempNotes;
  }
  getSocDetailsTablesData(data: any) {
    this.transactionDataSource = new MatTableDataSource(
      data.transactionHistory ? data.transactionHistory : []
    );
    this.partiesDataSource = new MatTableDataSource(
      data.partiesInvolved ? data.partiesInvolved : []
    );
    this.itemDataSource = new MatTableDataSource(
      data.itemDetail ? data.itemDetail.sort((a:any,b:any)=>a.itemNumber.localeCompare(b.itemNumber)) : []
    );
    this.dataSource = new MatTableDataSource(data.notes ? data.notes : []);
    setTimeout(() => {
      this.partiesDataSource.paginator = this.partiesPaginator;
      this.dataSource.paginator = this.notePaginator;
      this.itemDataSource.paginator = this.itemPaginator;
      this.transactionDataSource.paginator = this.transactionPaginator;

      this.dataSource.sort = this.matNotesSort;
      this.partiesDataSource.sort = this.matPartyInvolvedSort;
      this.itemDataSource.sort = this.matItemSort;
      // this.itemDataSource.sortData(this.itemDataSource.sort);
      this.transactionDataSource.sort = this.matTransactionSort;
    });
  }
  setCurrentStatus(currentSocData: any) {
    this.isEditableSoc = false;
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i].socStatusName == currentSocData.status) {
        this.selectedStatus = this.status[i];
        if (
          !currentSocData.docType.toLowerCase().includes('rectification') &&
          this.userSocMapings.editableSOC.includes(
            this.selectedStatus.socStatus
          )
        ) {
          this.isEditableSoc = true;
        }
        break;
      }
    }
    this.addDataTosectionDetail(this.userSocMapings);
  }
  addDataTosectionDetail(data: any) {
    data.sectionDetail.forEach((section: any) => {
      if (
        section.section == 'soc_details' ||
        section.section == 'customer_and_model_details'
      ) {
        section.fieldDetails.forEach((field: any) => {
          if (
            field.field == 'header_data' ||
            field.field == 'model' ||
            field.field == 'customer'
          ) {
            field.subFieldDetail.forEach((subField: any) => {
              subField['value'] = this.currentSocData[subField.subField];
            });
          }
        });
      }
    });
  }

  newSocStatus: any;
  statusUpdate(socActin: any) {
    this.currentSocAction = socActin;
    if (this.userRole != 'SP' && this.currentSocAction != 'send_to_sp') {
      if (this.currentSocAction == 'revalidate') {
        this.alertService.openDialogBox('Do you want to revalidate the claim?');
      } else if (this.currentSocAction == 'accept') {
        this.alertService.openDialogBox('Do you want to accept the claim?');
      } else if (this.currentSocAction == 'send_to_sp') {
        this.alertService.openDialogBox('Do you want claim to send to SP?');
      } else if (this.currentSocAction == 'reject') {
        this.alertService.openDialogBox('Do you want to reject the claim?');
      } else if (
        this.currentSocAction == 'save' ||
        this.currentSocAction == 'save_with_creaditmemo'
      ) {
        this.alertService.openDialogBox('Do you want to save the claim?');
      }
      this.alertService.dialogRef.afterClosed().subscribe((result: any) => {
        if (result == 'yes') {
          for (let i = 0; i < this.status.length; i++) {
            if (this.currentSocAction == 'revalidate') {
              if (this.status[i].socStatus == 'TVA') {
                this.newSocStatus = this.status[i];
                break;
              }
            } else if (this.currentSocAction == 'accept') {
              if (this.status[i].socStatus == 'AMA') {
                this.newSocStatus = this.status[i];
                break;
              }
            } else if (this.currentSocAction == 'send_to_sp') {
              if (this.status[i].socStatus == 'TCSP') {
                this.newSocStatus = this.status[i];
                break;
              }
            } else if (this.currentSocAction == 'reject') {
              if (this.status[i].socStatus == 'RJM') {
                this.newSocStatus = this.status[i];
                break;
              }
            } else if (
              this.currentSocAction == 'save_with_creaditmemo' ||
              this.currentSocAction == 'save'
            ) {
              /**keep same soc status for creaditmemo linked  */
              this.newSocStatus = this.selectedStatus;
              break;
            }
          }
          this.updateSoc();
        }
      });
    }
  }
  saveClaim() {
    this.alertService.openDialogBox('Do you want to save claim?');
    this.alertService.dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.commonService.showNotificaton(
          'success',
          'SUCCESS!',
          'SOC successfully updated.'
        );
      }
    });
  }
  deleteNote() {
    this.alertService.openDialogBox('Do you really want to delete this note?');
    this.alertService.dialogRef.afterClosed().subscribe((result: any) => {});
  }
  addNote() {
    for (let i = 0; i < this.status.length; i++) {
      if (this.currentSocAction == 'revalidate') {
        if (this.status[i].socStatus == 'TVA') {
          this.newSocStatus = this.status[i];
          break;
        }
      } else if (this.currentSocAction == 'accept') {
        if (this.status[i].socStatus == 'AMA') {
          this.newSocStatus = this.status[i];
          break;
        }
      } else if (this.currentSocAction == 'send_to_sp') {
        if (this.status[i].socStatus == 'TCSP') {
          this.newSocStatus = this.status[i];
          break;
        }
      } else if (this.currentSocAction == 'reject') {
        if (this.status[i].socStatus == 'RJM') {
          this.newSocStatus = this.status[i];
          break;
        }
      }
    }
    this.updateSoc();

    setTimeout(() => {
      this.addNoteForm.reset();
    }, 1000);
  }
  setActive() {
    $('.active').parent().get(0).css('bacground', 'gray');
  }
  getSocItems() {
    this.http.get('columnPreferenceApi/v1/getSocItems').subscribe(
      (data: any) => {
        this.socItemProductList = data.socItemDetails;
        this.prepareProductList(data.socItemDetails);
      },
      (error) => {}
    );
  }
  prepareProductList(produts: any) {
    this.filterProductList();
  }
  filterProductList() {
    this.filteredSocItemProductList = JSON.parse(
      JSON.stringify(this.socItemProductList)
    );
    if (this.isCreditMemoLinked) {
      var length = this.filteredSocItemProductList.length;
      while (length--) {
        if (
          this.filteredSocItemProductList[length].productId.toLowerCase() !=
            'travel' &&
          this.filteredSocItemProductList[length].productId.toLowerCase() !=
            'addons'
        ) {
          this.filteredSocItemProductList.splice(length, 1);
        }
      }
    }

    let indexes: any = [];
    this.filteredSocItemProductList.forEach((product: any, i: any) => {
      this.itemDataSource.data.forEach((item: any) => {
        if (
          item.productId
            .toLowerCase()
            .includes(product.productId.toLowerCase()) &&
          (!product.productId.toLowerCase().includes('visit') ||
            !product.productId.toLowerCase().includes('spare parts'))
        ) {
          indexes.push(i);
        }
      });
    });
    for (let j = indexes.length - 1; j >= 0; j--) {
      this.filteredSocItemProductList.splice(indexes[j], 1);
    }
  }
  selectProduct(event: any) {
    if (!this.isEditPoupUp) {
      this.selectedProduct.productId == 'TRAVEL'
        ? (this.selectedProduct.price = '5')
        : this.selectedProduct.productId == 'ADDONS'
        ? (this.selectedProduct.price = '')
        : (this.selectedProduct.price = 'Calculated in CRM');
      this.selectedProduct.quantity = 1;
    }
    const quantity = this.addEditItemForm.get('quantity');
    const price = this.addEditItemForm.get('price');
    event.priceEditable ? price.enable() : price.disable();
    event.quantityEditable ? quantity.enable() : quantity.disable();
    this.selectedProduct.itemNumber =
      this.selectedProduct.itemNumber.toString();
    if (this.selectedProduct.itemCategory.length == 1) {
      this.selectedProductItem = this.selectedProduct.itemCategory[0];
    } else {
      this.selectedProductItem = '';
    }
    if (
      this.selectedProduct['productId'].toLowerCase().includes('spare part')
    ) {
      this.isSparePartProduct = true;
      this.addEditItemForm
        .get('sparePartProductControl')
        .setValidators([Validators.required]);
      this.addEditItemForm
        .get('sparePartProductControl')
        .updateValueAndValidity();
    } else {
      this.isSparePartProduct = false;
      this.addEditItemForm.get('sparePartProductControl').clearValidators();
      this.addEditItemForm
        .get('sparePartProductControl')
        .updateValueAndValidity();
    }
    if (this.isEditPoupUp && this.isSparePartProduct) {
      this.selectedProductItem = this.selectedProduct.itemCategory[0];
    }
  }
  quantityChange(selectedProduct: any) {
    if (selectedProduct['productId'].toLowerCase().includes('travel')) {
      selectedProduct.price =
        parseFloat('5.00') * parseFloat(selectedProduct.quantity);
    }
  }
  editItem(element: any, index: any) {
    this.filterProductList();
    this.isSparePartProduct = false;
    this.addEditItemForm.get('sparePartProductControl').clearValidators();
    this.addEditItemForm
      .get('sparePartProductControl')
      .updateValueAndValidity();
    this.selectedProduct = {};
    this.selectedItemIndex = index;
    this.isEditPoupUp = true;
    this.selectedItem = element;
    this.selectedItemCurrency = element.currency;
    for (let i = 0; i < this.socItemProductList.length; i++) {
      if (
        element['productId']
          .toLowerCase()
          .includes(this.socItemProductList[i].productId.toLowerCase())
      ) {
        this.selectedProduct = this.socItemProductList[i];
        const quantity = this.addEditItemForm.get('quantity');
        const price = this.addEditItemForm.get('price');
        this.selectedProduct.priceEditable ? price.enable() : price.disable();
        this.selectedProduct.quantityEditable
          ? quantity.enable()
          : quantity.disable();
        this.selectedProduct.itemCategory.forEach((item: any) => {
          if (item.includes(element.itemCode)) {
            this.selectedProductItem = item;
          }
        });
        break;
      } else if (element['itemCode'].toLowerCase().includes('zvcm')) {
        if (
          this.socItemProductList[i].productId
            .toLowerCase()
            .includes('spare parts')
        ) {
          this.selectedProduct = this.socItemProductList[i];
          const quantity = this.addEditItemForm.get('quantity');
          const price = this.addEditItemForm.get('price');
          this.selectedProduct.priceEditable ? price.enable() : price.disable();
          this.selectedProduct.quantityEditable
            ? quantity.enable()
            : quantity.disable();
          this.selectedProduct.itemCategory.forEach((item: any) => {
            if (item.includes(element.itemCode)) {
              this.selectedProductItem = item;
            }
          });
          break;
        }
      }
    }

    this.selectedProduct.price = element.itemPrice.toString().trim();
    this.selectedProduct.quantity = element.itemQuantity.toString().trim();
    if (
      this.selectedProduct['productId'].toLowerCase().includes('spare part')
    ) {
      this.isSparePartProduct = true;
      this.selectedSparePartProduct = {
        productDescription: element.productDescription,
        productId: element.productId,
      };
      this.addEditItemForm
        .get('sparePartProductControl')
        .setValue(this.selectedSparePartProduct.productDescription);
      this.addEditItemForm
        .get('sparePartProductControl')
        .setValidators([Validators.required]);
      this.addEditItemForm
        .get('sparePartProductControl')
        .updateValueAndValidity();
    } else {
      this.isSparePartProduct = false;
      this.selectedSparePartProduct = {};
      this.addEditItemForm.get('sparePartProductControl').setValue('');
      this.addEditItemForm.get('sparePartProductControl').clearValidators();
      this.addEditItemForm
        .get('sparePartProductControl')
        .updateValueAndValidity();
    }
    this.filteredSocItemProductList.push(this.selectedProduct);
  }
  deleteItem(element: any, i: any) {
    this.isSparePartProduct = false;
    this.selectedSparePartProduct = {};
    this.addEditItemForm.get('sparePartProductControl').setValue('');
    this.addEditItemForm.get('sparePartProductControl').clearValidators();
    this.addEditItemForm
      .get('sparePartProductControl')
      .updateValueAndValidity();
    this.selectedItemIndex = i;
    this.selectedItem = element;
    this.alertService.openDialogBox('Do you want to delete this item?');
    this.alertService.dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        let itemDetail = {
          itemNumber: element.itemNumber,
          productguid: '',
          productcode: element.productId,
          productDescription: element.productDescription,
          unit: element.itemUnit,
          itemPrice: element.productId == 'ADDONS' ? element.itemPrice : '',
          currency: element.currency,
          itemCategory: element.itemCategory,
          itemtype: element.itemCode,
          deletionflag: 'true',
          itemqty: element.itemQuantity,
        };
        let checkDuplicateData = this.checkIfItemAredyExistInUpdateArray();
        if (checkDuplicateData.item_exist) {
          if (element['isLocallyAddedItem']) {
            this.itemsToBeUpdate.splice(checkDuplicateData.item_index, 1);
          } else {
            this.itemsToBeUpdate[checkDuplicateData.item_index] = itemDetail;
          }
        } else {
          this.itemsToBeUpdate.push(itemDetail);
        }
        this.itemDataSource.sortData(
          this.itemDataSource.filteredData,
          this.itemDataSource.sort
        );
        this.itemDataSource.data.splice(i, 1);
        this.itemDataSource._updateChangeSubscription();
      }

      for (let i = 0; i < this.itemsToBeUpdate.length; i++) {
        this.saveDisabled = true;
        this.tvmDisabled = true;
        if (this.selectedStatus.socStatus == 'AUT') {
          if (
            this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
            this.itemsToBeUpdate[i].productcode == 'TRAVEL'
          ) {
            this.saveDisabled = false;
            break;
          }
        } else if (
          this.selectedStatus.socStatus == 'AMA' &&
          (this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
            this.itemsToBeUpdate[i].productcode == 'TRAVEL')
        ) {
          this.saveDisabled = false;
        } else if (
          this.selectedStatus.socStatus == 'TVM' &&
          (this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
            this.itemsToBeUpdate[i].productcode == 'TRAVEL')
        ) {
          this.tvmDisabled = false;
        }
      }
      for (let i = 0; i < this.itemsToBeUpdate.length; i++) {
        /**SAVE button will be disabled in case  ZVCM (Spare) is added/amended/removed */
        if (
          this.selectedStatus.socStatus == 'AUT' &&
          this.itemsToBeUpdate[i].itemtype == 'ZVCM'
        ) {
          this.saveDisabled = true;
          break;
        }
      }
    });
  }

  addItem() {
    this.isEditPoupUp = false;
    this.selectedProduct = {};
    this.selectedProductItem = {};
    this.isSparePartProduct = false;
    this.selectedSparePartProduct = {};
    this.addEditItemForm.get('sparePartProductControl').setValue('');
    this.addEditItemForm.get('sparePartProductControl').clearValidators();
    this.addEditItemForm
      .get('sparePartProductControl')
      .updateValueAndValidity();
    this.filterProductList();
  }
  onSparePartProductSelection(e: any) {
    this.selectedSparePartProduct = e;
  }
  updateItemSubmit() {
    if (this.isEditPoupUp) {
      let itemDetail = {
        itemNumber: this.selectedItem ? this.selectedItem['itemNumber'] : '',
        productguid: '',
        productcode: this.isSparePartProduct
          ? this.selectedSparePartProduct.productId
          : this.selectedProduct
          ? this.selectedProduct.productId
          : '',
        productDescription: this.selectedProduct['productId']
          .toLowerCase()
          .includes('spare part')
          ? this.selectedSparePartProduct.productDescription
          : this.selectedProduct
          ? this.selectedProduct['productDescription']
          : '',
        unit: this.selectedProduct ? this.selectedProduct['unitOfMeasure'] : '',
        itemPrice:
          this.selectedProduct.productId == 'ADDONS'
            ? this.selectedProduct.price
            : '',
        currency: this.currency,
        itemCategory: this.addEditItemForm
          .get('item_category')
          .value.split(/ (.+)/)[1], //this.selectedItem.itemCategory,
        itemtype: this.addEditItemForm.get('item_category').value.split(' ')[0], //this.selectedItem.itemCode,
        deletionflag: 'false',
        itemqty: this.selectedProduct.quantity,
      };
      let itemTableObj = {
        itemNumber: this.selectedItem ? this.selectedItem['itemNumber'] : '',
        productguid: '',
        productId: this.isSparePartProduct
          ? this.selectedSparePartProduct.productId
          : this.selectedProduct
          ? this.selectedProduct.productId
          : '',
        productDescription: this.selectedProduct['productId']
          .toLowerCase()
          .includes('spare part')
          ? this.selectedSparePartProduct.productDescription
          : this.selectedProduct
          ? this.selectedProduct['productDescription']
          : '',
        itemUnit: this.selectedProduct
          ? this.selectedProduct['unitOfMeasure']
          : '',
        itemPrice:
          this.selectedProduct.price == 'Calculated in CRM'
            ? ''
            : this.selectedProduct.price,
        currency: this.currency,
        itemCategory: this.addEditItemForm
          .get('item_category')
          .value.split(/ (.+)/)[1], //this.selectedItem.itemCategory,
        itemtype: this.addEditItemForm.get('item_category').value.split(' ')[0], //this.selectedItem.itemCode,
        deletionflag: 'false',
        itemQuantity: this.selectedProduct.quantity,
        itemCode: this.addEditItemForm.get('item_category').value.split(' ')[0], //this.selectedItem.itemCode,
        isLocallyAddedItem: this.selectedItem['isLocallyAddedItem'],
      };

      let checkDuplicateData = this.checkIfItemAredyExistInUpdateArray();
      if (checkDuplicateData.item_exist) {
        this.itemsToBeUpdate[checkDuplicateData.item_index] = itemDetail;
      } else {
        this.itemsToBeUpdate.push(itemDetail);
      }
      this.itemDataSource.sortData(
        this.itemDataSource.filteredData,
        this.itemDataSource.sort
      );
      this.itemDataSource.data[this.selectedItemIndex] = itemTableObj;
      this.itemDataSource._updateChangeSubscription();
    } else {
      let itemDetail = {
        itemNumber: this.selectedProduct ? this.prepareItemNumber() : '',
        productguid: '',
        productcode: this.isSparePartProduct
          ? this.selectedSparePartProduct.productId
          : this.selectedProduct
          ? this.selectedProduct.productId
          : '',
        productDescription: this.selectedProduct['productId']
          .toLowerCase()
          .includes('spare part')
          ? this.selectedSparePartProduct.productDescription
          : this.selectedProduct
          ? this.selectedProduct['productDescription']
          : '',
        unit: this.selectedProduct ? this.selectedProduct['unitOfMeasure'] : '',
        itemPrice:
          this.selectedProduct.productId == 'ADDONS'
            ? this.selectedProduct.price
            : '',
        currency: this.currency,
        itemCategory: this.addEditItemForm
          .get('item_category')
          .value.split(/ (.+)/)[1],
        itemtype: this.addEditItemForm.get('item_category').value.split(' ')[0],
        deletionflag: 'false',
        itemqty: this.selectedProduct.quantity,
      };
      let itemTableObj = {
        itemNumber: this.selectedProduct ? this.prepareItemNumber() : '',
        productguid: '',
        productId: this.isSparePartProduct
          ? this.selectedSparePartProduct.productId
          : this.selectedProduct
          ? this.selectedProduct.productId
          : '',
        productDescription: this.selectedProduct['productId']
          .toLowerCase()
          .includes('spare part')
          ? this.selectedSparePartProduct.productDescription
          : this.selectedProduct
          ? this.selectedProduct['productDescription']
          : '',
        itemUnit: this.selectedProduct
          ? this.selectedProduct['unitOfMeasure']
          : '',
        itemPrice:
          this.selectedProduct.price == 'Calculated in CRM'
            ? ''
            : this.selectedProduct.price,
        currency: this.currency,
        itemCategory: this.addEditItemForm
          .get('item_category')
          .value.split(/ (.+)/)[1],
        itemtype: this.addEditItemForm.get('item_category').value.split(' ')[0],
        deletionflag: 'false',
        itemQuantity: this.selectedProduct.quantity,
        itemCode: this.addEditItemForm.get('item_category').value.split(' ')[0],
        isLocallyAddedItem: true,
      };
      this.itemsToBeUpdate.push(itemDetail);
      this.itemDataSource.sortData(
        this.itemDataSource.filteredData,
        this.itemDataSource.sort
      );
      this.itemDataSource.data.push(itemTableObj);
      this.itemDataSource._updateChangeSubscription();
    }

    for (let i = 0; i < this.itemsToBeUpdate.length; i++) {
      this.saveDisabled = true;
      this.tvmDisabled = true;
      if (this.selectedStatus.socStatus == 'AUT') {
        if (
          this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
          this.itemsToBeUpdate[i].productcode == 'TRAVEL'
        ) {
          this.saveDisabled = false;
          break;
        }
      } else if (
        this.selectedStatus.socStatus == 'AMA' &&
        (this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
          this.itemsToBeUpdate[i].productcode == 'TRAVEL')
      ) {
        this.saveDisabled = false;
        break;
      } else if (
        this.selectedStatus.socStatus == 'TVM' &&
        (this.itemsToBeUpdate[i].productcode == 'ADDONS' ||
          this.itemsToBeUpdate[i].productcode == 'TRAVEL')
      ) {
        this.tvmDisabled = false;
        break;
      }
    }
    for (let i = 0; i < this.itemsToBeUpdate.length; i++) {
      /**SAVE button will be disabled in case  ZVCM (Spare) is added/amended/removed */
      if (
        this.selectedStatus.socStatus == 'AUT' &&
        this.itemsToBeUpdate[i].itemtype == 'ZVCM'
      ) {
        this.saveDisabled = true;
        break;
      }
    }
  }
  checkIfItemAredyExistInUpdateArray() {
    let item_exist = false;
    let item_index: any;
    for (let i = 0; i < this.itemsToBeUpdate.length; i++) {
      /**Check if item alredy exist in  itemsToBeUpdate array*/
      if (this.selectedItem.itemNumber == this.itemsToBeUpdate[i].itemNumber) {
        item_exist = true;
        item_index = i;
        break;
      } else {
        item_exist = false;
      }
    }
    return { item_exist: item_exist, item_index: item_index };
  }
  prepareItemNumber() {
    if (this.itemDataSource.data.length > 0) {
      let max = this.itemDataSource.data[0].itemNumber;
      this.itemDataSource.data.forEach((item: any) => {
        if (item.itemNumber > max) {
          max = item.itemNumber;
        }
      });
      if (max.length > 6) {
        max = +max + 10;
        return ('0000000000' + max).slice(-10);
      } else {
        max = +max + 1;
        return ('000000' + max).slice(-6);
      }
    } else {
      return '000100';
    }
  }
  resetItems() {
    this.alertService.openDialogBox('Do you want to reset the items.?');
    this.alertService.dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['home/soc-details', this.currentSocData.socId]);
        this.router.navigate(['home/soc-details', this.currentSocData.socId], {
          queryParams: { for: this.routParam },
        });
      }
    });
  }
  statusChange(actionName: any) {
    if (actionName == 'revalidate') {
    }
  }

  nextSoc() {
    let nextSOcId = this.socId;
    let socFound = false;
    for (let i = 0; i < this.socListData.length; i++) {
      if (this.socId == this.socListData[i].socId) {
        this.currentSocIndex = i;
        socFound = true;
        if (this.socListData[i + 1]) {
          nextSOcId = this.socListData[i + 1].socId;
        }
        break;
      }
    }
    if (!socFound) {
      this.currentSocIndex = this.currentSocIndex + 1;
      if (this.socListData[this.currentSocIndex]) {
        nextSOcId = this.socListData[this.currentSocIndex].socId;
      }
    }
    if (this.currentSocIndex < this.socListData.length - 1) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['home/soc-details', nextSOcId]);
      this.router.navigate(['home/soc-details', nextSOcId], {
        queryParams: { for: this.routParam },
      });
    }
  }
  previousSoc() {
    let nextSOcId = this.socId;
    let socFound = false;
    for (let i = 0; i < this.socListData.length; i++) {
      if (this.socId == this.socListData[i].socId) {
        this.currentSocIndex = i;
        socFound = true;
        if (this.socListData[i - 1]) {
          nextSOcId = this.socListData[i - 1].socId;
        }
        break;
      }
    }
    if (!socFound) {
      this.currentSocIndex = this.currentSocIndex - 1;
      if (this.socListData[this.currentSocIndex]) {
        nextSOcId = this.socListData[this.currentSocIndex].socId;
      }
    }
    if (this.currentSocIndex > 0) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['home/soc-details', nextSOcId]);
      this.router.navigate(['home/soc-details', nextSOcId], {
        queryParams: { for: this.routParam },
      });
    }
  }
  onSwipeLeft(event: any) {
    if (this.innerWidth < 1024) {
      this.currentAnimationState = 'right';
      this.nextSoc();
    }
  }
  onSwipeRight(event: any) {
    if (this.innerWidth < 1024) {
      this.currentAnimationState = 'left';
      this.previousSoc();
    }
  }
  onTap(evt: any) {
    this.nextSoc();
  }

  selectProductItem(data: any) {
    this.selectedItemCurrency = data.currency;
    this.creaditLinkedAndZukdItem = false;
    if (this.isCreditMemoLinked) {
      if (data.startsWith('ZUKD')) {
        this.creaditLinkedAndZukdItem = true;
      } else {
        this.creaditLinkedAndZukdItem = false;
      }
    }
  }
  updateSoc() {
    this.loaderService.start();
    let updateDto = {
      id: this.currentSocData.id,
      socStatus: this.newSocStatus.socStatus,
      userRoles: this.userRole,
      notes: {
        textType: this.userRole == 'SP' ? 'ZSP1' : 'ZDM1',
        text: this.addNoteForm.get('text').value,
      },
      itemDetail: this.itemsToBeUpdate,
      servicetype: this.serviceTypeValue,
    };
    this.http.post('claimApi/v1/updateSocStatus', updateDto).subscribe(
      (data: any) => {
        this.itemsToBeUpdate = [];
        if (data.status && data.status == 'ERROR') {
          this.loaderService.stop();
          this.alertService.openErrorDialogBox(
            'Something went wrong. Please try again or contact admin!'
          );
        } else {
          this.commonService.showNotificaton(
            'success',
            'SUCCESS!',
            'SOC successfully updated.'
          );
          this.retriveSocList();
        }
      },
      (err) => {
        this.loaderService.stop();
        console.log(err);
      }
    );
  }
  retriveSocList() {
    this.loaderService.start();
    let data = {
      
      accountingIndicator: [],
      appointmentDateFrom: '',
      callDateFrom: '',
      creationDateFrom: '',
      appointmentDateTo:'',
      callDateTo: '',
      creationDateTo:'',
      customer: [],
      documentStatus: [],
      language: 'en',
      searchType: '01',
      serviceType: this.receivedData.serviceType
        ? this.receivedData.serviceType.map((i: any) => {
            return i.serviceType;
          })
        : [],
      soId: [],
      socid: [this.socId] ? [this.socId] : [],
      socDbId: this.resSocData.id ? this.resSocData.id : [],
      spCustomerCode: this.receivedData.servicePartners
        ? this.receivedData.servicePartners.map((i: any) => {
            return i.spId;
          })
        : [],
      technician: [],
      userId: this.userId,
      userProfile: this.userRole,
      toBeUpdated: true,
      isConsolidated: this.routParam=='consolidated-claim'?true:false,
    };

    this.http.post('claimApi/v1/retrieveSocList', data).subscribe(
      (res) => {
        // this.commonService.showNotificaton(
        //   'success',
        //   'SUCCESS!',
        //   'SOC successfully updated.'
        // );
        if (res.status == 'ERROR') {
          this.alertService.openErrorDialogBox(
            'Something went wrong. Please try again or contact admin!'
          );
          this.router.navigate(['home/consolidated-claim']);
        } else {
          this.getSOCs();
          // setTimeout(() => {
          //   this.getSOCs();
          //   console.log('10000 ms');
          // }, 10000);
        }
      },
      (err) => {
        this.loaderService.stop();
        console.log(err);
      }
    );
  }

  getSOCs() {
    // let data: any = this.dataShareService.getLocalStorage('socFilterParameter');
    let data = {
      accountingIndicator: [],
      appointmentDateFrom: '',
      appointmentDateTo: '',
      callDateFrom: '',
      callDateTo: '',
      creationDateFrom: '',
      creationDateTo: '',
      customerNumber: [],
      documentStatus: [],
      documentType: [],
      externalReference: [],
      serviceType: [],
      technician: [],
      documentNumber:[],
      spVendorCode: this.receivedData.servicePartners
      ? this.receivedData.servicePartners.map((i: any) => {
          return i.spId;
        })
      : [],
      userId: this.userId,
      token: this.token,
      isConsolidated: this.routParam == 'consolidated-claim' ? true : false,
    };
    if (!data) {
      if (this.routParam == 'consolidated-claim') {
        this.router.navigate(['home/consolidated-claim']);
      } else {
        this.router.navigate(['home/claim-management']);
      }
    }
    this.http.post('claimApi/v1/filterSoc', data).subscribe(
      (data: any) => {
        data.response.socInfoDetails.sort((a:any,b:any)=>a.socId-b.socId);
        this.getSocListData(data.response.socInfoDetails);
        this.dataShareService.setSocData(data.response.socInfoDetails);
        this.dataShareService.currentFormSocData.subscribe((getSocData) => {
          this.listSocData = JSON.parse(getSocData);
          console.log('listSocData' + this.listSocData);
          this.listSocData.forEach((element: any) => {
            console.log('element' + element);
            if (element.socId === this.socId) {
              this.resSocData = element;
              this.currentSocData = element;

            }
          });
          console.log('Recieved from SOC deatils data:', this.resSocData);
        });
        // this.currentSocData = data.response.socInfoDetails[0];
        this.dataShareService.setLocalStorage(
          'currentSocId',
          this.currentSocData.socId
        );
        this.currency = this.currentSocData.itemDetail
          ? this.currentSocData.itemDetail[0].currency
          : '';
        this.checkForCreaditmemolinked(); /** check if credit memo link*/
        this.reArrangeNotes();
        this.getSocItems();
        this.setCurrentStatus(this.currentSocData);
        this.getSocDetailsTablesData(this.currentSocData);
        this.loaderService.stop();
      },
      (error) => {
        this.loaderService.stop();
      }
    );
  }

  getSOCAfterRevalidate() {
    let dto = {
      accountingIndicator: [],
      appointmentDateFrom: '',
      appointmentDateTo: '',
      callDateFrom: '',
      callDateTo: '',
      creationDateFrom: '',
      creationDateTo: '',
      customerNumber: [],
      documentStatus: [],
      documentType: [],
      externalReference: [],
      serviceType: [],
      technician: [],
      documentNumber: this.socId,
      spVendorCode: [],
      userId: this.userId,
      token: this.token,
      isConsolidated: this.routParam == 'consolidated-claim' ? true : false,
    };

    this.http.post('claimApi/v1/filterSoc', dto).subscribe(
      (data: any) => {
        this.currentSocData = data.response.socInfoDetails[0];
        this.dataShareService.setLocalStorage(
          'currentSocId',
          this.currentSocData.socId
        );
        this.currency = this.currentSocData.itemDetail
          ? this.currentSocData.itemDetail[0].currency
          : '';
        this.checkForCreaditmemolinked(); /** check if credit memo link*/
        this.reArrangeNotes();
        this.getSocItems();
        this.setCurrentStatus(this.currentSocData);
        this.getSocDetailsTablesData(this.currentSocData);
        this.loaderService.stop();
        
      },
      (err) => {
        this.loaderService.stop();
        console.log(err);
      }
    );
  }

  retriveSocListForConsolidated() {
    this.loaderService.start();
    // let data :any;
    // if(this.dataShareService.getLocalStorage('retriveSocListRequestDto')){
    // data= this.dataShareService.getLocalStorage('retriveSocListRequestDto');
    // data = JSON.parse(data);
    // data['socid']=[this.socId];
    // data["isConsolidated"]=this.routParam=='consolidated-claim'? true:false;
    // }else{
    let data = {
      accountingIndicator: [],
      appointmentDateFrom: '',
      callDateFrom: '',
      creationDateFrom: '',
      appointmentDateTo: '',
      callDateTo: '',
      creationDateTo: '',
      customer: [],
      documentStatus: [],
      language: 'en',
      searchType: '01',
      serviceType: [],
      soId: [],
      socid: [this.socId],
      spCustomerCode:
        this.userRole == 'SP'
          ? [this.dataShareService.getLocalStorage('customerNumber')]
          : [] /**Also known as customerId */,
      technician: [],
      userId: this.dataShareService.getLocalStorage('userId'),
      userProfile: this.dataShareService.getLocalStorage('role'),
      toBeUpdated: false,
      socDbId: '',
      isConsolidated: this.routParam == 'consolidated-claim' ? true : false,
    };
    // }

    this.http.post('claimApi/v1/retrieveSocList', data).subscribe(
      (data) => {
        if (data.status == 'ERROR') {
          this.alertService.openErrorDialogBox(
            'Something went wrong. Please try again or contact admin!'
          );
          this.router.navigate(['home/consolidated-claim']);
        } else {
          this.getSOCs();
        }
        // this.loaderService.stop();
      },
      (error) => {
        this.loaderService.stop();
      }
    );
  }
}
