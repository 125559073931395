<div class="msg-dialog wec-dialog">
  <mat-dialog-content class="mat-typography text-center">
    <h3>{{ data }}</h3>
  </mat-dialog-content>
  <mat-dialog-actions class="padding-bottom-30 action">
    <div class="w-100 text-center">
      <button class="mat-raised-button buttons" (click)="okay()">Yes</button>
      <button class="mat-raised-button ml-3 buttons" (click)="close()">
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</div>
