import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  public socListData: any;
  public consolidatedList: any;
  private storageSub = new Subject<string>();
  public filterSocRequestParameter: any;
  public filterConsolidateRequestParameter: any;
  public consolidatedMainFilterFlag: boolean = true;
  public claimMainFilterFlag: boolean = true;
  public retriveSocListRequestDto: any;
  public claimAdvanceFilterStatusList: any = [];
  public claimAdvanceFilterDocumentTypeList: any = [];
  public claimAdvanceFilterAccountingIndicatorList: any = [];
  public claimAdvanceFilterServiceTypeList: any = [];
  public myFormData = new BehaviorSubject<string>('[]');
  public getSocData = new BehaviorSubject<string>('[]');
  public getConsRetriveData = new BehaviorSubject<string>('[]');
  public getConsFilterData = new BehaviorSubject<string>('[]');
  constructor() {}
  currentFormData = this.myFormData.asObservable();
  currentFormSocData = this.getSocData.asObservable();
  referConsRetriveData = this.getConsRetriveData.asObservable();
  referConsFilterData = this.getConsFilterData.asObservable();

  /**
   * Set Claim Management Retrieve Data
   */
  setData(data: any[]): void {
    const jsonString = JSON.stringify(data);
    this.myFormData.next(jsonString);
  }
  /**
   * Set Claim Management Filter Data
   */

  setSocData(data: any[]): void {
    const socData = JSON.stringify(data);
    this.getSocData.next(socData);
  }
  /**
   * Set Consolidated Retrieve Data
   */
  setConsRetrieveData(data: any[]): void {
    const getCRD = JSON.stringify(data);
    this.getConsRetriveData.next(getCRD);
  }

  // Set Consolidated filter Data
  setConsFilterData(data: any): void {
    const getCFD = JSON.stringify(data);
    this.getConsFilterData.next(getCFD);
  }
  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }
  setSocListData(data: any) {
    this.socListData = data;
  }
  getSocListData() {
    return this.socListData;
  }
  setConsolidatedList(data: any) {
    this.consolidatedList = data;
  }
  getConsolidatedList() {
    return this.consolidatedList;
  }
  setFilterSocRequestParameter(data: any) {
    this.filterSocRequestParameter = data;
  }
  getFilterSocRequestParameter() {
    return this.filterSocRequestParameter;
  }
  setFilterConsolidatedRequestParameter(data: any) {
    this.filterConsolidateRequestParameter = data;
  }
  getFilterConsolidateRequestParameter() {
    return this.filterConsolidateRequestParameter;
  }
  setConsolidatedMainFilterFlag(value: boolean) {
    this.consolidatedMainFilterFlag = value;
  }
  getConsolidatedMainFilterFlag() {
    return this.consolidatedMainFilterFlag;
  }
  setClaimMainFilterFlag(value: boolean) {
    this.claimMainFilterFlag = value;
  }
  getClaimMainFilterFlag() {
    return this.claimMainFilterFlag;
  }
  setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
    this.storageSub.next('added');
  }
  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }
  setRetriveSocListRequestDto(data: any) {
    this.retriveSocListRequestDto = data;
  }
  getRetriveSocListRequestDto() {
    return this.retriveSocListRequestDto;
  }

  setClaimadvanceFilterStatusList(data: any) {
    this.claimAdvanceFilterStatusList = data;
  }
  setClaimAdvanceFilterDocumentTypeList(data: any) {
    this.claimAdvanceFilterDocumentTypeList = data;
  }
  setClaimAdvanceFilterAccountingIndicatorList(data: any) {
    this.claimAdvanceFilterAccountingIndicatorList = data;
  }
  setClaimAdvanceFilterServiceTypeList(data: any) {
    this.claimAdvanceFilterServiceTypeList = data;
  }

  getClaimadvanceFilterStatusList() {
    return this.claimAdvanceFilterStatusList;
  }
  getClaimAdvanceFilterDocumentTypeList() {
    return this.claimAdvanceFilterDocumentTypeList;
  }
  getClaimAdvanceFilterAccountingIndicatorList() {
    return this.claimAdvanceFilterAccountingIndicatorList;
  }
  getClaimAdvanceFilterServiceTypeList() {
    return this.claimAdvanceFilterServiceTypeList;
  }

  removeItem(key: any) {
    localStorage.removeItem(key);
    this.storageSub.next('removed');
  }

  clearData() {
    localStorage.clear();
    this.storageSub.next('removedAll');
    this.filterSocRequestParameter = '';
    this.filterConsolidateRequestParameter = '';
    this.socListData = '';
    this.consolidatedMainFilterFlag = true;
    this.claimMainFilterFlag = true;
    this.retriveSocListRequestDto = '';
    this.claimAdvanceFilterStatusList = [];
    this.claimAdvanceFilterDocumentTypeList = [];
    this.claimAdvanceFilterAccountingIndicatorList = [];
    this.claimAdvanceFilterServiceTypeList = [];
  }
}
