<!-- <aside class="left-nav">
    <nav class="sidenav">
        <a href="#" class="logo"><img src="../../assets/img/logo.png" width="110" alt="logo"></a>
        <ul class="menu-list">
            <li class="menu-item" routerLink='/claim-management' routerLinkActive="active">
                <a class="menu"> <span>
                        <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
                    </span> Claim Management</a>
                <img src="../../assets/img/path.png" alt="path">
            </li>
            <li class="menu-item" routerLink='/consolidated-claim' routerLinkActive="active">
                <a class="menu">
                    <span>
                        <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
                    </span> Consolidated claim</a>
                <img src="../../assets/img/path.png" alt="path">
            </li>
            <li class="menu-item" routerLink='/new-claim' routerLinkActive="active">
                <a class="menu">
                    <span>
                        <mat-icon aria-hidden="false" aria-label="Example home icon">note_add</mat-icon>
                    </span>New Claim</a>
                <img src="../../assets/img/path.png" alt="path">
            </li>
        </ul>
    </nav>
    <router-outlet></router-outlet>
</aside> -->

<div class="wrapper">
    <mat-sidenav-container class="example-container" autosize>
        <mat-sidenav #drawer class="example-sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
            [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" fullscreen>
            <!-- <app-side-nav *ngIf="sidenav && !isLoginPage"></app-side-nav> -->
            <aside class="left-nav">
                <nav class="sidenav">
                    <a href="#" class="logo"><img
                            src="https://storage.googleapis.com/whp-sp-portal-prod.appspot.com/images/Service_logo_square.JPG"
                            width="250px" alt="logo"></a>
                    <ul class="menu-list">
                        <li class="menu-item" routerLink='claim-management' (click)="claimTab()"
                            routerLinkActive="active">
                            <a class="menu"> <span>
                                    <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
                                </span> Claim Management</a>
                            <img src="../../assets/img/path.png" alt="path">
                        </li>
                        <li class="menu-item" routerLink='consolidated-claim' (click)="consolidatedTab()"
                            routerLinkActive="active">
                            <a class="menu">
                                <span>
                                    <mat-icon aria-hidden="false"
                                        aria-label="Example home icon">edit_document</mat-icon>
                                </span> Consolidated claim</a>
                            <img src="../../assets/img/path.png" alt="path">
                        </li>
                        <li class="menu-item" routerLink='soap-logs' (click)="soaplogTab()" routerLinkActive="active"
                            *ngIf="userRole=='AM'">
                            <a class="menu">
                                <span>
                                    <mat-icon>pending_actions</mat-icon>
                                </span>Logs</a>
                            <img src="../../assets/img/path.png" alt="path">
                        </li>
                        <!-- <li class="menu-item" routerLink='new-claim' routerLinkActive="active">
                            <a class="menu">
                                <span>
                                    <mat-icon aria-hidden="false" aria-label="Example home icon">note_add</mat-icon>
                                </span>New Claim</a>
                            <img src="../../assets/img/path.png" alt="path">
                        </li> -->
                    </ul>
                </nav>
                <!-- <router-outlet></router-outlet> -->
            </aside>
        </mat-sidenav>
        <mat-sidenav-content class="example-sidenav-content">

            <header class="header">
                <app-header (SideNavToggle)="drawer.toggle()"></app-header>
            </header>
            <main [ngClass]="{'main': !isLoginPage}">
                <router-outlet></router-outlet>
            </main>

        </mat-sidenav-content>
    </mat-sidenav-container>

</div>